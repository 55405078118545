import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { AdminService } from '../../service/admin.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { SuperAdminService } from '../../service/super-admin.service';

@Component({
  selector: 'rc-add-entity-modal',
  templateUrl: './add-entity-modal.component.html',
  styleUrls: ['./add-entity-modal.component.css']
})
export class AddEntityModalComponent {

  @Input()
  org_types: Array<any> = [];

  @Input()
  subscriber_types: Array<any> = [];

  createEntityForm: FormGroup;
  loading = false;
  submitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private adminService: AdminService,
    private superAdminService: SuperAdminService,
    private toastr: ToastrService,
    public activeModal: NgbActiveModal
  ) {
    this.createEntityForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      webDomain: [''],
      primaryEmailDomain: [''],
      altEmailDomain: [''],
      orgType: ['-1', [Validators.required]],
      subscriberType: ['-1', [Validators.required]]
    })
  }

  get f() { return this.createEntityForm.controls; }

  onSubmit() {
    this.submitted = true;

    if (this.createEntityForm.invalid) return;

    this.spinner.show();

    this.superAdminService.registerEntity(this.createEntityForm.value).subscribe({
      next: (res: any) => {
        this.toastr.success(res.body.message);
        this.spinner.hide();
        this.activeModal.close(res.body);
      }, error: (err) => {
        console.log('Error', err)
        this.toastr.error(err.error.message);
        this.spinner.hide();
      }, complete: () => { }
    })
  }

}
