import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { APP_ADMIN_SERVICE_API_URL } from '../../app.constant';

@Injectable({
  providedIn: 'root'
})
export class SuperAdminService {

  constructor(
    private http: HttpClient,
  ) { }


  getAddSubscribersData(): Observable<Array<any>> {
    return this.http.get<Array<any>>(APP_ADMIN_SERVICE_API_URL + '/get_add_subscribers_data');
  }

  registerEntity(entityData: any) {
    return this.http.post<any>(APP_ADMIN_SERVICE_API_URL + '/register_entity', entityData, { observe: 'response' });
  }

  getSubscriberCount(): Observable<number> {
    return this.http.get<number>(APP_ADMIN_SERVICE_API_URL + '/get_subscriber_count');
  }

  
}
