<div class="d-flex justify-content-between align-items-center">
    <div style="margin-bottom: 10px;">
        <h2>{{pageLabel}}</h2>
        <span id="span1" *ngIf="showSelectedGridDataCountText">{{selectedGridDataCountText}}</span>
        <span id="span2" class="cursor-pointer" *ngIf="showTotalGirdDataCountText"
            (click)="onSelectTotalGridData()">Select all {{totalGirdDataCountText}} instead</span>
        <span id="span2" class="cursor-pointer" *ngIf="showClearSelection" (click)="onClearSelection()">Clear
            selection</span>
    </div>
    <div>
        <button *ngIf="showBulkActionButton" type="button" class="btn btn-primary" id="openPopupButton"
            [ngbPopover]="bulkActionPopContent" [placement]="'bottom-right'" [autoClose]="true">
            BULK ACTIONS <i class="fas fa-caret-down" aria-hidden="true"></i>
        </button>

        <button *ngIf="showDynamicQueryButton" type="button" class="btn btn-primary" id="openPopupButton"
            (click)="openDynamicQueryModal()">
            FILTERS({{dynamicQueryFilters?.rules?.length ?? 0}}) <i class="fa fa-plus-circle" aria-hidden="true"></i>
        </button>

        <button type="button" class="btn btn-primary" id="openPopupButton" (click)="openColumnFilterModal()">
            <i class="fa fa-bars" aria-hidden="true"> </i> COLUMNS
        </button>
    </div>
</div>

<div class="table-container" id="shipmentContainer">
    <table class="table table-striped table-bordered" id="shipmentTable">
      <thead class="fixed-header">
        <tr *ngIf="showTitleHeaderForTable">
          <th colspan="50" class="header-title" style="background-color: #1B6532 !important;">2024 Halloween Harvest</th>
        </tr>
        <tr class="cursor-pointer">
          <th scope="col" class="icon-centered" (click)="onSelectAllCurrentPageGridData()" *ngIf="showCheckboxColumn">
            <input class="cursor-pointer" type="checkbox" [(ngModel)]="selectAllCurrentPageGridData">
          </th>
          <th scope="col" *ngIf="showTimelineColumn"> View Timeline</th>
          <ng-container *ngFor="let column of gridHeaderList">
            <th scope="col" *ngIf="column !== 'id'" (click)="sortGridData(column)">
              {{ column }}
              <i class="fa-solid fa-circle-arrow-up" *ngIf="paginationData.sortColumn === getSortColumnKey(column) && paginationData.sortOrder === '1'"></i>
              <i class="fa-solid fa-circle-arrow-down" *ngIf="paginationData.sortColumn === getSortColumnKey(column) && paginationData.sortOrder === '-1'"></i>
            </th>
          </ng-container>
          <th scope="col" *ngIf="showActionsColumn">Actions</th>
        </tr>
      </thead>
      <tbody *ngIf="gridData.length > 0">
        <ng-container *ngFor="let item of gridData">
          <tr>
            <td class="icon-centered" *ngIf="showCheckboxColumn">
              <input class="cursor-pointer" type="checkbox" [checked]="isSelected(item)" (click)="toggleSelectedRows(item)">
            </td>
            <td *ngIf="showTimelineColumn" class="text-center icon-cellMore" (click)="onViewTimeline(item)">
              <div class="dropdown">
                <i class="fa-solid fa-timeline" aria-hidden="true"></i>
              </div>
            </td>
            <ng-container *ngFor="let field of gridColumnList">
              <td [ngStyle] ="{'text-align': isNumber(item[field]) ? 'right': 'left'}" 
                  *ngIf="field !== 'id' && field !== 'items'" 
                  [style.cursor]="(activateCellClick && cellClickColumnList.includes(field)) ? 'pointer' : 'default'" 
                  (click)="activateCellClick ? onCellClickHandler(field, item) : null">
                <ng-container *ngIf="isDate(item[field]); else currencyFormat">
                  {{ item[field] | date: 'MM/dd/yyyy' }}
                </ng-container>

                <ng-template #currencyFormat>
                  <ng-container  *ngIf="isCurrency(field); else decimalFormat">
                    {{ item[field] | currency:'USD':'symbol':'1.2-2' }}
                  </ng-container>
                
                  <ng-template #decimalFormat>
                    <ng-container *ngIf="field === 'status' || field === 'stage'; else noFormatDisplay">
                      {{ formatValues(item[field]) }}
                    </ng-container>
                    
                    <ng-template #noFormatDisplay>
                      {{ item[field] | decimalFormat }}
                    </ng-template>
                    <ng-container *ngIf="item?.items?.length > 1">
                      <span *ngIf="(filterCriteria.type == 'po_filters' && field == 'po_number') || (filterCriteria.type == 'shipment_filters' && field == 'bl_number')" 
                      class="cursor-pointer expandItems" 
                      (click)="shouldExpandRow(item) ? item.isExpanded = !item.isExpanded : null"> 
                      <br>
                      <i *ngIf="!item.isExpanded" class="fa fa-plus-circle" aria-hidden="true"></i> 
                      <i *ngIf="item.isExpanded" class="fa fa-minus-circle" aria-hidden="true"></i>
                      <b> View {{item?.items?.length}} {{filterCriteria.type == 'po_filters' ? 'Items' : 'POs'}} </b>
                    </span>
                    </ng-container>
                  </ng-template>
                </ng-template>
                <p *ngIf="item[field] == null || item[field] == ''">
                  <span>_</span>
                </p>
              </td>
            </ng-container>
            <td *ngIf="showActionsColumn" class="text-center icon-cellMore" [ngbPopover]="popContent" [placement]="'bottom'" container="body" [autoClose]="true">
              <div class="dropdown">
                <i class="fas fa-ellipsis-v" aria-hidden="true"></i>
              </div>
            </td>
            <ng-template class="overlay-pic" #popContent>
              <div class="dropdown-menu show">
                <a class="dropdown-item">Action 1</a>
                <a class="dropdown-item">Action 2</a>
                <a class="dropdown-item">Action 3</a>
              </div>
            </ng-template>
          </tr>
          <tr *ngIf="item.isExpanded && shouldExpandRow(item)" class="expanded-row">
            <td *ngIf="showCheckboxColumn"></td>
            <td *ngIf="showTimelineColumn"></td>
            <ng-container *ngFor="let field of gridColumnList">
              <td [ngStyle] ="{'text-align': isNumber(item[field]) ? 'right': 'left'}" 
                  *ngIf="field !== 'id' && field !== 'items'"
                  [style.cursor]="(activateCellClick && cellClickColumnList.includes(field)) ? 'pointer' : 'default'" >
                <ng-container *ngFor="let subItem of item.items">
                  <span (click)="activateCellClick ? onCellClickHandler(field, subItem) : null">
                    
                    <ng-container *ngIf="isDate(subItem[field]); else currencyFormat">
                      <div class="expanded-cell" [ngClass]="{'has-content': subItem[field]}">{{ subItem[field] | date: 'MM/dd/yyyy' }}</div>
                    </ng-container>

                    <ng-template #currencyFormat>
                      <ng-container *ngIf="isCurrency(field); else decimalFormat">
                        <div class="expanded-cell" [ngClass]="{'has-content': subItem[field]}">{{ subItem[field] | currency:'USD':'symbol':'1.2-2' }}</div>
                      </ng-container>
                      
                      <ng-template #decimalFormat>
                        <ng-container *ngIf="field === 'status' || field === 'stage'; else noFormatDisplay">
                          <div class="expanded-cell" [ngClass]="{'has-content': subItem[field]}">{{ formatValues(subItem[field]) }}</div>
                        </ng-container>
                        
                        <ng-template #noFormatDisplay>
                          <div class="expanded-cell" [ngClass]="{'has-content': subItem[field]}">{{ subItem[field] | decimalFormat}}</div>
                        </ng-template>
                      </ng-template>
                    </ng-template>
                  
                  </span>
                </ng-container>
              </td>
            </ng-container>
            <td *ngIf="showActionsColumn" class="text-center icon-cellMore"></td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
  

<div *ngIf="gridData.length>0" class="pagination-controls">
    <div>
        <span>Show rows per page: </span>
        <select class="cursor-pointer" [(ngModel)]="paginationData.pageSize"
            (ngModelChange)="onPageSizeChange($event)">
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
        </select>
    </div>
    <div class="pagination-info">
        <span id="numberspan">{{startItemCount}} - {{endItemCount}} of {{total}}</span>
        <ngb-pagination [collectionSize]="total" [(page)]="paginationData.currentPage"
            [pageSize]="paginationData.pageSize" [maxSize]="5" (pageChange)="onPaginationChange($event)">
        </ngb-pagination>
    </div>
</div>



<span *ngIf="gridData.length<1">{{noGridDataMessage}}</span>

<ng-template class="overlay-pic" #bulkActionPopContent>
    <div class="dropdown-menu show">
        <a class="dropdown-item">Action 1</a>
        <a class="dropdown-item">Action 2</a>
        <a class="dropdown-item">Action 3</a>
    </div>
</ng-template>