<ngx-spinner bdColor="rgba(51,51,51, 0.8)" color="#fff" [fullScreen]="true" size="medium">
</ngx-spinner>

<!-- <div id="myModal" style="display: block;">
    <div class="modal-dialog">
        <div class="modal-content"> -->
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                    Create a New Entity
                </h5>
                <button type="button" class="btn-close-custom" (click)="activeModal.dismiss('X')">
                    <img src="assets/images/cross_white.png" alt="Close">
                </button>
            </div>
            <div class="modal-body">
                <form id="createEntityForm" [formGroup]="createEntityForm" (ngSubmit)="onSubmit()" autocomplete="off">
                    <div class="mb-3">
                        <label for="name" class="form-label">Entity Name:</label>
                        <input type="text" id="name" name="name" placeholder="New Entity Name *" class="form-control"
                            formControlName="name" [ngClass]="{ 'is-invalid': submitted && f['name'].errors }" />
                        <div *ngIf="submitted && f['name'].errors" class="invalid-feedback">
                            <div *ngIf="f['name'].errors['required']">Entity name is required</div>
                        </div>
                    </div>
                    <div class="mb-3">
                        <label for="web_domain" class="form-label">Web Domain:</label>
                        <input type="text" id="web_domain" name="web_domain" placeholder="Main Web Domain *" class="form-control"
                            formControlName="webDomain" />
                    </div>
                    <div class="mb-3">
                        <label for="primary_email_domain" class="form-label">Primary Email Domain:</label>
                        <input type="text" id="primary_email_domain" name="primary_email_domain" placeholder="Primary Email Domain"
                            class="form-control" formControlName="primaryEmailDomain" />
                    </div>
            
                    <div class="mb-3">
                        <label for="alt_email_domain" class="form-label">Alternative Email Domain:</label>
                        <input type="text" id="alt_email_domain" name="alt_email_domain" placeholder="Alternate Email Domain"
                            class="form-control" formControlName="altEmailDomain" />
                    </div>
            
                    <div class="mb-3">
                        <label for="org_type" class="form-label">Org Types:</label>
                        <select name="org_type" id="org_type" class="form-control form-control" formControlName="orgType" [ngClass]="{ 'is-invalid': submitted && f['orgType'].errors }">
                            <option [value]="-1" hidden>Select Org Type</option>
                            <option *ngFor="let org_type of org_types" [value]="org_type.type">
                                {{org_type.type}}
                            </option>
                        </select>
                        <div *ngIf="submitted && f['orgType'].errors" class="invalid-feedback">
                            <div *ngIf="f['orgType'].errors['required']">This field is required</div>
                        </div>
                    </div>
            
                    <div class="mb-3">
                        <label for="subscriber_type" class="form-label">Subscriber Types:</label>
                        <select name="subscriber_type" id="subscriber_type" class="form-control form-control" formControlName="subscriberType" [ngClass]="{ 'is-invalid': submitted && f['subscriberType'].errors }">
                            <option [value]="-1" hidden>Select Subscriber Type</option>
                            <option *ngFor="let subscriber_type of subscriber_types" [value]="subscriber_type.type">
                                {{subscriber_type.type}}
                            </option>
                        </select>
                        <div *ngIf="submitted && f['subscriberType'].errors" class="invalid-feedback">
                            <div *ngIf="f['subscriberType'].errors['required']">This field is required</div>
                        </div>
                    </div>
            
                    <button type="submit" class="btn btn-primary btn" id="dashboard_Button">
                        Register Entity
                    </button>
                </form>
            </div>
            
<!-- </div>
    </div>
</div> -->