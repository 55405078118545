import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject, of, timer, Subscription } from 'rxjs';
import { shareReplay, tap, catchError, map } from 'rxjs/operators';

import { StateStorageService } from './state-storage.service';
import { Account } from '../model/account.model';
import { AUTH_SERVICE_API_URL, APP_USERS_SERVICE_API_URL } from '../../app.constant';
import { AuthService } from './auth.service';
import { GlobalService } from './global.service';

@Injectable({
  providedIn: 'root'
})
export class MetaDataService {

  constructor(
    private http: HttpClient
  ) { }

  getDynamicQueryMetadata(postData: any) {
    return this.http.post<any>(APP_USERS_SERVICE_API_URL + '/get_dynamic_query_metadata', postData, { observe: 'response' });
  }

  getInitialReportsAnalysisInputMetadata() {
    return this.http.get<any>(APP_USERS_SERVICE_API_URL + '/get_initial_reports_analysis_input_metadata', { observe: 'response' });
  }

}
