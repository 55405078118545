import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject, of, timer, Subscription } from 'rxjs';
import { shareReplay, tap, catchError, map } from 'rxjs/operators';

import { StateStorageService } from './state-storage.service';
import { Account } from '../model/account.model';
import { AUTH_SERVICE_API_URL, APP_USERS_SERVICE_API_URL } from '../../app.constant';
import { AuthService } from './auth.service';
import { GlobalService } from './global.service';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  public loginEmitter = new EventEmitter();
  public subscriberChangeEmitter = new EventEmitter();

  private userIdentity: Account | null = null;
  private authenticationState = new ReplaySubject<Account | null>(1);
  private accountCache$?: Observable<Account | null>;


  constructor(
    private http: HttpClient,
    private stateStorageService: StateStorageService,
    private router: Router,
    private authService: AuthService,
    private globalService: GlobalService
  ) { }

  authenticate(identity: Account | null): void {
    this.userIdentity = identity;
    this.authenticationState.next(this.userIdentity);
  }

  hasAnyAuthority(authorities: string[] | string): boolean {

    if (!this.userIdentity || !this.authService.getToken()) {
      return false;
    }
    if (!Array.isArray(authorities)) {
      authorities = [authorities];
    }
    return authorities.includes(this.userIdentity.role);
  }

  identity(force?: boolean): Observable<Account | null> {
    if (!this.accountCache$ || force || !this.isAuthenticated()) {
      this.accountCache$ = this.fetch().pipe(
        catchError(() => of(null)),
        tap((account: Account | null) => {
          this.authenticate(account);
          if (account) {
            if (this.authService.getRole() != 'RESICHAIN_EMPLOYEE') {
              this.authService.setCookies('RC-X-selected-entity-id', account.entity_id)
            }
            this.globalService.user = account
            this.loginEmitter.emit(account);
          }
        }),
        shareReplay()
      );
    }
    return this.accountCache$;
  }

  isAuthenticated(): boolean {
    return this.userIdentity !== null;
  }

  fetch(): Observable<Account> {
    return this.http.get<Account>(APP_USERS_SERVICE_API_URL + '/get_user_profile');
  }

  activate(postData: any) {
    return this.http.post<any>(APP_USERS_SERVICE_API_URL + '/activate', postData, { observe: 'response' });
  }

}


