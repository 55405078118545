import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../service/user.service';

@Component({
  selector: 'rc-simple-generic-modal',
  templateUrl: './simple-generic-modal.component.html',
  styleUrls: ['./simple-generic-modal.component.css']
})
export class SimpleGenericModalComponent implements OnInit{

  @Input() rowData: any;

  titleStringMap: any = {
    event_name: 'Event Name',
    event_subcode: 'Event Subcode',
    product_category: 'Product Category',
    bl_number: 'BL Number',
    vessel_name: 'Vessel Name',
    container_number: 'Container Number',
    container_size: 'Container Size',
    dc_code: 'DC (Code)',
    load_type: 'FTL / LTL',
    pallet_count: 'Pallet Count',
    cft: 'Total CFT',
    total_gross_weight: 'Total GW'
  }

  popupData: any = {};
  popupDataKeys: any = {};

  constructor(
    private activeModal: NgbActiveModal,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.loadAll();
  }

  loadAll() {
    this.userService.getColumnPopupData(this.rowData).subscribe({
      next: (res: any) => {
        // console.log("res", res);
        this.popupData = res.body;
        this.popupDataKeys = Object.keys(this.popupData);
      },
      error: (err) => {
        console.log("err", err);
      },
    })
  }

  objectKeys(obj: any): string[] {
    return Object.keys(obj);
  }

  onNo() {
    this.activeModal.dismiss('X')
  }

}
