import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject, of, timer, Subscription } from 'rxjs';
import { shareReplay, tap, catchError, map } from 'rxjs/operators';

import { StateStorageService } from './state-storage.service';
import { Account } from '../model/account.model';
import { INGESTION_SERVICE_API_URL } from '../../app.constant';
import { AuthService } from './auth.service';
import { GlobalService } from './global.service';

@Injectable({
  providedIn: 'root'
})
export class IngestionService {

  constructor(
    private http: HttpClient
  ) { }

  ingestCSV() {
    return this.http.get(INGESTION_SERVICE_API_URL + '/ingest_csv', { observe: 'response' })
  }

  ingestExcel() {
    return this.http.get(INGESTION_SERVICE_API_URL + '/ingest_excel', { observe: 'response' })
  }

  insertMappings() {
    return this.http.get(INGESTION_SERVICE_API_URL + '/insert_mappings', { observe: 'response' })
  }

  ingestion_test() {
    return this.http.get(INGESTION_SERVICE_API_URL + '/ingestion_test', { observe: 'response' })
  }

  getUploadedFilesMetadata() {
    return this.http.get(INGESTION_SERVICE_API_URL + '/get_subscriber_uploaded_files', { observe: 'response' });
  }

  upload(formData: FormData) {
    return this.http.post(INGESTION_SERVICE_API_URL + '/upload', formData, { observe: 'response' });
  }

  testapi() {
    return this.http.get(INGESTION_SERVICE_API_URL + '/testapi', { observe: 'response' });
  }

  getAllMappingObjectTypes() {
    return this.http.get(INGESTION_SERVICE_API_URL + '/get_all_mapping_object_types', { observe: 'response' });
  }

  getFileColumnMappingData(postBody: any) {
    return this.http.post(INGESTION_SERVICE_API_URL + '/get_file_column_mapping_data', postBody, { observe: 'response' });
  }

  getFileColumnMappingDataFromColumnFlavour(postBody: any) {
    return this.http.post(INGESTION_SERVICE_API_URL + '/get_file_column_mapping_data_from_column_flavour', postBody, { observe: 'response' });
  }

  saveColumnMappingData(postBody: any) {
    return this.http.post(INGESTION_SERVICE_API_URL + '/save_column_mapping_data', postBody, { observe: 'response' });
  }

  saveColumnMappingDataWithoutUploadedFiles(postBody: any) {
    return this.http.post(INGESTION_SERVICE_API_URL + '/save_column_mapping_data_without_uploaded_files', postBody, { observe: 'response' });
  }

  fileContentPreview(postBody: any) {
    return this.http.post(INGESTION_SERVICE_API_URL + '/file_content_preview', postBody, { observe: 'response' });
  }

  getSubscriberUploadedFilesMetadata(postBody: any) {
    return this.http.post(INGESTION_SERVICE_API_URL + '/get_subscriber_uploaded_files_metadata', postBody, { observe: 'response' });
  }
}
