<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">{{currentStepObj.stepTitle}}</h5>
  <button type="button" class="btn-close-custom" (click)="onNo()">
    <img src="assets/images/cross_white.png" alt="Close" />
  </button>
</div>
<div class="modal-body">
  <div class="card-body">
    <div class="mb-3">
      <div class="step-indicator">
        <ng-container *ngFor="let stepObj of threeStepsArrayObj; let last = last;">
          <ng-container *ngIf="!editMode">
            <span [class.active]="currentStepObj.step === stepObj.step">{{stepObj.stepTitle}}</span>
            <span *ngIf="!last">&nbsp;&gt;&gt;&nbsp;</span>
          </ng-container>
          <ng-container *ngIf="editMode">
            <span [class.active]="currentStepObj.step === stepObj.step"
            [class.cursor-pointer]="currentStepObj.step != stepObj.step && uploadedFiles.length < 1 && (stepObj.step === 1 || stepObj.step === 2)"
            (click)="changeStep(stepObj)">{{stepObj.stepTitle}}</span>
            <span *ngIf="!last">&nbsp;&gt;&gt;&nbsp;</span>
          </ng-container>
        </ng-container>
      </div>
    </div>

    <!-- step-1 upload file(s) -->
    <div class="step-content">

      <div *ngIf="currentStepObj.step === 1">
        <p id="stepsSpan">Upload Files (Step 1 of 3)</p>

        <div class="upload-box" (drop)="onDrop($event)" (dragover)="onDragOver($event)"
          (dragleave)="onDragLeave($event)">
          <p>Drag & Drop files here or</p>
          <input type="file" id="fileInput" (change)="onFileAdd($event)" accept=".csv, .xlsx, .xls" hidden multiple />
          <button type="button" class="btn btn-primary" (click)="chooseFile()">Choose Files</button>
        </div>

        <div class="file-info">
          <p>Supported formats: CSV, XLS, XLSX</p>
          <p class="right">Max file size: 25MB</p>
        </div>

        <div *ngIf="addedFiles.length > 0" class="upload-label-wrapper">
          <p class="upload-label">Upload Labels:</p>
          <table class="file-table">
            <thead>
              <tr>
                <th>File Name</th>
                <th>Size of the File</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let file of addedFiles">
                <td>{{ file.name }}</td>
                <td>{{ (file.size / (1024 * 1024)).toFixed(2) }} MB</td>
              </tr>
            </tbody>
          </table>
          <button type="button" id="clearButton" class="btn btn-primary" (click)="clearFiles()">Clear Files</button>
        </div>

        <div *ngIf="showWarningText" class="invalid-feedback" style="display: block;">
          <div>{{warningText}}</div>
        </div>

        <div class="main-selection mt-4 mb-4">
          <label for="mainSelection">Select the main data type represented in your upload:</label>
          <select id="mainSelection" [(ngModel)]="selectedDataType" [disabled]="this.editMode">
            <option [value]="null" hidden>Data Type...</option>
            <option *ngFor="let mappingObjectType of mappingObjectTypes" [value]="mappingObjectType.object_type_name">{{ dataTypeMap[mappingObjectType.object_type_name] }}</option>
          </select>
        </div>

        <div *ngIf="selectedDataType === 'custom'" class="data-mapping-name mb-4">
          <label for="dataMappingName">Name your data mapping:</label>
          <input type="text" id="dataMappingName" [(ngModel)]="dataMappingName">
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-primary" (click)="onNo()">Cancel</button>

          <button *ngIf="containsFlagFile" type="button" class="btn btn-primary" (click)="onSaveAndExit(1)">
            Save & Exit</button>

          <button *ngIf="!containsFlagFile" type="button" [disabled]="isButtonsDisabled(1)" class="btn btn-primary"
            (click)="onSaveAndExit(1)">Save &
            Exit</button>
          <button *ngIf="!containsFlagFile" type="button" [disabled]="isButtonsDisabled(1)" class="btn btn-primary" (click)="onNext(1)">Proceed to
            Mapping</button>
        </div>

      </div>


      <!-- step-2 Map Data -->
      <div *ngIf="currentStepObj.step === 2">
        <p id="stepsSpan">Map Data (Step 2 of 3)</p>
        <table class="map-table">
          <thead>
            <tr>
              <th>Data Description</th>
              <th>Data Source (Uploaded Column Name)</th>
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let data of dataDescriptionMappings">
              <td>{{ data.display_name }}</td>
              <td>
                <select id="mspOptions2" [ngModel]="data.file_column" (change)="onDataSourceChange(data, $event.target)">
                  <option [value]="null" hidden>Data Source...</option>
                  <option *ngFor="let column of fileColumns" [value]="column">{{ column }}</option>
                </select>
                &nbsp;
                <i class="fa-solid fa-xmark cursor-pointer" (click)="removeMapping(data)"></i>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" (click)="onNo()">Cancel</button>
          <button type="button" [disabled]="isButtonsDisabled(2)" class="btn btn-primary"
            (click)="onSaveAndExit(2)">Save &
            Exit</button>
          <button *ngIf="uploadedFiles.length > 0" type="button" [disabled]="isButtonsDisabled(2)" class="btn btn-primary" (click)="onNext(2)">Proceed to
            Review</button>
        </div>
      </div>


      <!-- step-3 Review -->
      <div *ngIf="currentStepObj.step === 3">
        <p id="stepsSpan">Review Files (Step 3 of 3)</p>
        <table class="file-review-detail-table">
          <thead>
            <tr>
              <th>File Name</th>
              <th>Uploaded On</th>
              <th># Mapped Columns</th>
              <th>Total Rows</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let fileStatus of fileStatuses">
              <td>{{ fileStatus?.file_name }}</td>
              <td>{{ fileStatus?.uploaded_on | date: 'MM-dd-yyyy' }}</td>
              <td>{{ fileStatus?.mapped_column }}</td>
              <td>{{ fileStatus?.total_rows }}</td>
              <td>
                <label class="switch">
                  <a href="javascript:void(0)" (click)="fileContentPreview(fileStatus?.file_name)">
                    <span class="slider">preview File</span>
                  </a>
                </label>
              </td>
            </tr>
          </tbody>
        </table>

        <!-- <div *ngIf="showFilePreview" class="detail-table-wrapper">
          <p id="reviewCaption">To confirm you have uploaded the correct files, you can preview the first 10 rows of data below</p>
          <p>File name: <b>{{ fileContent?.file_name }}</b></p>
          <div class="table-container">
            <table class="file-review-detail-table">
              <thead>
                <tr>
                  <th *ngFor="let key of fileContent?.top_rows?.[0] | keyvalue">{{ key.key }}</th>
                </tr>
              </thead>
              <tbody>

                <tr *ngFor="let row of fileContent?.top_rows">
                  <td *ngFor="let cell of row | keyvalue">{{ cell.value }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div> -->

        <div *ngIf="showFilePreview" class="detail-table-wrapper">
          <p id="reviewCaption">
            To confirm you have uploaded the correct files, you can preview the first 10 rows of data below
          </p>
          <p>
            File name: <b>{{ fileContent?.file_name }}</b>
          </p>
          <div class="table-responsive" id="tableContainer">
            <table id="fileReviewTable" class="table table-bordered table-hover">
              <thead class="table-light">
                <tr>
                  <th *ngFor="let key of fileContent?.top_rows?.[0] | keyvalue">{{ key.key }}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let row of fileContent?.top_rows">
                  <td *ngFor="let cell of row | keyvalue">{{ cell.value }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="modal-footer">
          <!-- <button type="button" class="btn btn-primary" (click)="onNo()">Cancel</button> -->
          <button type="button" class="btn btn-primary" (click)="onSaveAndExit(3)">Close</button>
        </div>

      </div>

    </div>
  </div>
</div>