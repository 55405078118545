import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'rc-admin',
    templateUrl: './admin.component.html',
    styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {

    }

}
