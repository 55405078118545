<!-- Mob menu -->
<!-- <header class="mobile-header">
  <div class="mobile-header-top">

    <div class="hamburger-menu" (click)="toggleMenu()" *ngIf="user">
      <i class="fas fa-bars fa-xl"></i>
    </div>

    <div class="header-logo">
      <a href="#">
        <img src="assets/images/favicons/favicon_16_16.ico" alt="Logo" class="mobileHeaderLogo" alt="Company Logo">
        <span style="color: white;">Resichain</span>
      </a>
    </div>

    <div class="account-details" *ngIf="user">
      <span>Subscriber: {{ user.entity_name }}</span>
      <div class="user-details">
        <span>User: {{ user.email }}</span>
      </div>
    </div>

  </div>
  <div class="mobile-header-bottom">
    <img src="assets/images/dataGreen.png" alt="Data" class="mobileHeaderLogo" alt="Data Green">
    <marquee>Holiday files updated as of 9/20. Please report any data inconsistencies to the ResiChain team for further
    investigation.</marquee> 
  </div>
</header> -->

<header class="mobile-header">
  <div class="mobile-header-top">
    <!-- Left section for Hamburger Menu -->
    <div class="hamburger-menu" (click)="toggleMenu()" *ngIf="user">
      <i class="fas fa-bars fa-xl"></i>
    </div>

    <!-- Right section for Header Logo -->
    <div class="header-logo">
      <a href="#">
        <img src="assets/images/favicons/favicon_16_16.ico" alt="Logo" class="mobileHeaderLogo">
        <span style="color: white;">Resichain</span>
      </a>
    </div>
  </div>

  <!-- Tooltip and Account Details container -->
  <div *ngIf="user" class="tooltip-account-wrapper">
    <!-- Tooltip on the right -->
    <div class="tooltip-container">
      <img *ngIf="isInjestionDateLatest" src="assets/images/dataGreen.png" height="35px" alt="Data" class="mobileHeaderLogo">
      <img *ngIf="!isInjestionDateLatest" src="assets/images/dataYellow.png" height="35px" alt="Data" class="mobileHeaderLogo">
      <div id="user-tooltip" class="tooltip">{{ latestFileIngestionDateMessage }}</div>
      <div class="custom-white-bg account-details" *ngIf="user">
        <span>Subscriber: {{ user.entity_name }}</span>
        <div class="user-details">
          <span>User: {{ user.email }}</span>
        </div>
      </div>
    </div>
    
  </div>
</header>



<div *ngIf="menuVisible && user" class="mobile-nav">
  <div class="close-menu" (click)="toggleMenu()">
    <i class="fas fa-times"></i>
  </div>
  <ul>

    <li>
      <a href="/dashboard" class="nav_link">
        <i class="fa-solid fa-gauge-high nav_icon"></i>
        <p>Dashboard</p>
      </a>
    </li>

    <li>
      <a href="/pages/alerts" class="nav_link">
        <i class="fa-solid fa-bell nav_icon"></i>
        <p>Alerts</p>
      </a>
    </li>

    <li>
      <a href="/pages/orders" class="nav_link">
        <i class="fa-solid fa-cart-shopping nav_icon"></i>
        <p>Orders</p>
      </a>
    </li>

    <li>
      <a href="/pages/shipments" class="nav_link">
        <i class="fa-solid fa-truck-fast nav_icon"></i>
        <p>Shipments</p>
      </a>
    </li>

    <!-- <li>
      <a href="/pages/reports-and-analysis" class="nav_link">
        <i class="fa-solid fas fa-chart-pie nav_icon"></i>
        <p>Reports And Analysis</p>
      </a>
    </li> -->

    <!-- <li>
      <a href="/pages/suppliers" class="nav_link">
        <i class="fa-solid fa-boxes-stacked nav_icon"></i>
        <p>Suppliers</p>
      </a>
    </li>

    <li>
      <a href="/pages/couriers" class="nav_link">
        <i class="fa-solid fa-dolly nav_icon"></i>
        <p>Couriers</p>
      </a>
    </li> -->

    <li *ngIf="user && (user.role === 'ADMIN' || user.role === 'RESICHAIN_EMPLOYEE')">
      <a href="/admin/administrator" class="nav_link">
        <i class="fa-solid fa-lock nav_icon"></i>
        <p>Settings</p>
      </a>
    </li>

    <li>
      <a (click)="openChangePasswordModal()" class="nav_link cursor-pointer">
        <i class="fa-solid fa-key nav_icon"></i>
        <p>Change Password</p>
      </a>
    </li>

    <li>
      <div class="logout" *ngIf="user">
        <a class="nav_link" (click)="onLogout()">
          <i class="fa-solid fa-right-from-bracket nav_icon"></i>
          <p>Logout</p>
        </a>
      </div>
    </li>

  </ul>
</div>


<!-- Desktop menu -->
<header class="header" id="header">
  <div class="header_toggle">
    <i class="bx bx-menu" id="header-toggle"></i>
  </div>
  <div class="head-card">
    <!-- <span class="notification">
      Holiday files updated as of 9/20. Please report any data inconsistencies to the ResiChain team for further
      investigation.
    </span> -->
  </div>
  <div class="nav-access d-flex align-items-center">
    <div *ngIf="user" class="d-flex align-items-center">
      <div class="tooltip-container d-flex align-items-center">
        <img *ngIf="isInjestionDateLatest" src="assets/images/dataGreen.png" height="35px" alt="Data"
          class="mobileHeaderLogo" style="margin-right: 10px;">
        <img *ngIf="!isInjestionDateLatest" src="assets/images/dataYellow.png" height="35px" alt="Data"
          class="mobileHeaderLogo" style="margin-right: 10px;">
        <div id="user-tooltip" class="tooltip">{{ latestFileIngestionDateMessage }}</div>
      </div>
      <div class="d-flex flex-column">
        <p style="margin: 0;">Subscriber: {{ user.entity_name }}</p>
        <span class="d-flex align-items-center">User: {{ user.email }}</span>
      </div>
    </div>
  </div>




</header>

<div class="side-nav" [class.expanded]="isExpanded" [style.width]="isExpanded ? '243px' : '80px'">
  <div class="nav_list" *ngIf="user">
    <div class="toggle-button" (click)="toggleSidebar()">
      <i *ngIf="!isExpanded" class="fa-solid fa-bars fa-xl"></i>
      <i *ngIf="isExpanded" class="fa fa-times fa-xl" aria-hidden="true"></i>
    </div>
  </div>
  <div class="logo">
    <img src="assets/images/favicons/favicon_16_16.ico" alt="Logo" class="logo-img" />
    <div class="company_logo" *ngIf="isExpanded">
      <h2>Resichain</h2>
    </div>
  </div>
  <div class="nav_list" *ngIf="user">
    <ul id="sidebar_ul">

      <li>
        <a href="/dashboard" class="nav_link" title="Dashboard">
          <i class="fa-solid fa-gauge-high nav_icon"></i>
          <p *ngIf="isExpanded">Dashboard</p>
        </a>
      </li>

      <li>
        <a href="/pages/alerts" class="nav_link" title="Alerts">
          <i class="fa-solid fa-bell nav_icon"></i>
          <p *ngIf="isExpanded">Alerts</p>
        </a>
      </li>

      <li>
        <a href="/pages/orders" class="nav_link" title="Orders">
          <i class="fa-solid fa-cart-shopping nav_icon"></i>
          <p *ngIf="isExpanded">Orders</p>
        </a>
      </li>

      <li>
        <a href="/pages/shipments" class="nav_link" title="Shipments">
          <i class="fa-solid fa-truck-fast nav_icon"></i>
          <p *ngIf="isExpanded">Shipments</p>
        </a>
      </li>

      <!-- <li>
        <a href="/pages/suppliers" class="nav_link" title="Suppliers">
          <i class="fa-solid fa-boxes-stacked nav_icon"></i>
          <p *ngIf="isExpanded">Suppliers</p>
        </a>
      </li>

      <li>
        <a href="/pages/couriers" class="nav_link" title="Couriers">
          <i class="fa-solid fa-dolly nav_icon"></i>
          <p *ngIf="isExpanded">Couriers</p>
        </a>
      </li> -->

      <!-- <li>
        <a href="/pages/reports-and-analysis" class="nav_link" title="Reports And Analysis">
          <i class="fa-solid fas fa-chart-pie nav_icon"></i>
          <p *ngIf="isExpanded">Reports And Analysis</p>
        </a>
      </li> -->

      <li *ngIf="user.role === 'ADMIN' || user.role === 'RESICHAIN_EMPLOYEE'">
        <a href="/admin/administrator" class="nav_link" title="Settings">
          <i class="fa-solid fa-lock nav_icon"></i>
          <p *ngIf="isExpanded">Settings</p>
        </a>
      </li>

      <li>
        <a (click)="openChangePasswordModal()" class="nav_link cursor-pointer" title="Change Password">
          <i class="fa-solid fa-key nav_icon"></i>
          <p *ngIf="isExpanded">Change Password</p>
        </a>
      </li>

    </ul>
  </div>
  <div class="logout" *ngIf="user">
    <a class="nav_link" (click)="onLogout()" title="Logout">
      <i class="fa-solid fa-right-from-bracket nav_icon"></i>
      <p *ngIf="isExpanded">Logout</p>
    </a>
  </div>
</div>