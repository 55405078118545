import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject, of, timer, Subscription } from 'rxjs';
import { shareReplay, tap, catchError, map } from 'rxjs/operators';

import { StateStorageService } from './state-storage.service';
import { Account } from '../model/account.model';
import { APP_ADMIN_SERVICE_API_URL, APP_USERS_SERVICE_API_URL, AUTH_SERVICE_API_URL } from '../../app.constant';
import { AuthService } from './auth.service';
import { GlobalService } from './global.service';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(
    private http: HttpClient,
  ) { }

  getSubscribersList(): Observable<Array<any>> {
    return this.http.get<Array<any>>(APP_USERS_SERVICE_API_URL + '/get_subscribers_list');
  }

  getAddUsersData(): Observable<Array<any>> {
    return this.http.get<Array<any>>(APP_ADMIN_SERVICE_API_URL + '/get_add_users_data');
  }

  addUser(userData: any) {
    return this.http.post<any>(APP_ADMIN_SERVICE_API_URL + '/add_user', userData, { observe: 'response' });
  }

  UpdateUserRole(postData: any) {
    return this.http.post<any>(APP_ADMIN_SERVICE_API_URL + '/update_user_role', postData, { observe: 'response' });
  }

  resendUserInvitation(postData: any) {
    return this.http.post<any>(APP_ADMIN_SERVICE_API_URL + '/resend_user_invitation', postData, { observe: 'response' });
  }

  removeUser(postData: any) {
    return this.http.post<any>(APP_ADMIN_SERVICE_API_URL + '/remove_user', postData, { observe: 'response' });
  }

  getSavedDynamicQueryFilters(): Observable<Array<any>> {
    return this.http.get<Array<any>>(APP_ADMIN_SERVICE_API_URL + '/get_all_dynamic_query_filters');
  }

  saveDynamicQueryFilter(postData: any) {
    return this.http.post<any>(APP_ADMIN_SERVICE_API_URL + '/save_dynamic_query_filters', postData, { observe: 'response' });
  }

  editDynamicQueryFilter(postData: any) {
    return this.http.post<any>(APP_ADMIN_SERVICE_API_URL + '/edit_dynamic_query_filters', postData, { observe: 'response' });
  }

  deleteDynamicQueryFilter(postData: any) {
    return this.http.post<any>(APP_ADMIN_SERVICE_API_URL + '/delete_dynamic_query_filters', postData, { observe: 'response' });
  }

  createApiKey() {
    return this.http.get<any>(AUTH_SERVICE_API_URL + '/create_api_token', { observe: 'response' });
  }

  getSubcriberApiKeys() {
    return this.http.get<any>(APP_ADMIN_SERVICE_API_URL + '/get_subcriber_api_keys', { observe: 'response' });
  }

}
