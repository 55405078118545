<div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">
        Confirmation
    </h5>
    <button type="button" class="btn-close-custom" (click)="onNo()">
        <img src="assets/images/cross_white.png" alt="Close">
    </button>
</div>
<div class="modal-body">
    <div class="card-body">
        <div class="mb-3">
            <p [innerHTML]="text"></p>
        </div>
        <button type="button" class="btn btn-primary btn" id="confirmationButton" (click)="onNo()">
            {{noText}}
        </button>
        <button type="button" class="btn btn-primary btn" id="confirmationButton" (click)="onYes()">
            {{yesText}}
        </button>
    </div>
</div>