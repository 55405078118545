<div class="card">
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
            Your Supply Chain (Details)
        </h5>
        <button type="button" class="btn-close-custom" (click)="activeModal.dismiss('X')">
            <img src="assets/images/cross_white.png" alt="Close">
        </button>
    </div>
    <div class="card-body" style="max-height: 600px; overflow-y: auto;">
        <div class="scroll-container" style="width: 100%; overflow-x: auto;">
            <p *ngIf="locationData.location_type != 'Port'" style="text-align: center;"><b>{{locationData.address_string}}</b></p>
            <p *ngIf="locationData.location_type != 'Port'" style="text-align: center;"><b>{{locationData.location_type}} for {{locationData.customer}}</b></p>
            <p *ngIf="locationData.location_type == 'Port'" style="text-align: center;"><b>{{locationData.address_string}} ({{locationData.location_type}})</b></p>
            <hr>
            <dl>
                <dt>POs Received: <span [ngClass]="{ 'cursor-pointer': locationData.location_type != 'HQ'}" (click)="goToOrderPage('po_recieved', locationData)">{{supplyChainDetails?.po_received?.total}}</span></dt>
                <ul>
                    <li>
                        On-time: <span [ngClass]="{ 'cursor-pointer': locationData.location_type != 'HQ'}" (click)="goToOrderPage('po_recieved_on_time', locationData)">{{supplyChainDetails?.po_received?.on_time}}</span>
                    </li>
                    <li>
                        Delayed: <span [ngClass]="{ 'cursor-pointer': locationData.location_type != 'HQ'}" (click)="goToOrderPage('po_recieved_delayed', locationData)">{{supplyChainDetails?.po_received?.delayed}}</span>
                    </li>
                </ul>
            </dl>
            <dl>
                <dt>Open POs: <span [ngClass]="{ 'cursor-pointer': locationData.location_type != 'HQ'}" (click)="goToOrderPage('po_open', locationData)">{{supplyChainDetails?.po_open?.total}}</span></dt>
                <ul>
                    <li>
                        On-time: <span [ngClass]="{ 'cursor-pointer': locationData.location_type != 'HQ'}" (click)="goToOrderPage('po_open_on_time', locationData)">{{supplyChainDetails?.po_open?.on_time}}</span>
                    </li>
                    <li>
                        Delayed: <span [ngClass]="{ 'cursor-pointer': locationData.location_type != 'HQ'}" (click)="goToOrderPage('po_open_delayed', locationData)">{{supplyChainDetails?.po_open?.delayed}}</span>
                    </li>
                </ul>
            </dl>
            <hr>
            <!-- <p>Upcoming Shipments</p> -->
            <dl>
                <dt>Upcoming Shipments</dt>
                <ul>
                    <li *ngFor="let shipment of supplyChainDetails?.shipments_upcoming">
                        {{shipment.eta_dc | date: 'MM-dd-yyyy' }}: PO#: {{shipment.po_number}} | Vendor: {{shipment.vendor}} | Origin:
                        {{shipment.port_of_loading}} | Status: {{shipment.status}}
                    </li>
                </ul>
            </dl>
            <hr>
            <!-- <p>Recent Shipments</p> -->
            <dl>
                <dt>Recent Shipments</dt>
                <ul>
                    <li *ngFor="let shipment of supplyChainDetails?.shipments_recent">
                        {{shipment.dc_ATA}}: PO#: {{shipment.po_number}} | Vendor: {{shipment.vendor}} | Origin:
                        {{shipment.port_of_loading}} | Status: {{shipment.status}}
                    </li>
                </ul>
            </dl>
        </div>
    </div>
</div>