import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { QueryBuilderClassNames, QueryBuilderConfig } from 'ngx-angular-query-builder';
import { MetaDataService } from '../../service/meta-data.service';
import { GlobalService } from '../../service/global.service';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../service/user.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'rc-dynamic-query-modal',
  templateUrl: './dynamic-query-modal.component.html',
  styleUrls: ['./dynamic-query-modal.component.css']
})
export class DynamicQueryModalComponent implements OnInit {

  @Input() pageLabel: string = '';
  @Input() dynamicQueryFilterDescription: string = '';
  @Input() yesText: string = '';
  @Input() noText: string = '';
  @Input() filterCriteria: any = { id: -1, type: 'Data Type...' };
  @Input() dynamicQueryFilters: any = {};
  @Input() savedDynamicQueryFilterObj: any = {};
  @Input() enableDynamicQueryFilterSave: boolean = false;
  @Input() savedDynamicQueryFilters: Array<any> = [];
  @Input() filterTypes: Array<any> = [];
  @Input() filterTypesMap: any = {};
  @Input() dynamicQueryFilterName: string = '';

  defaultFilterType: any = { id: -1, type: 'Data Type...' };

  infoPopoverText: string = '';

  secondaryFilter: number = 0;
  secondaryDynamicQueryFilterInfoPopoverText: string = 'Certain filters are automatically added and used when you click on insights that have complex definitions, for which equivalent filter conditions cannot be added manually one by one.';
  showSecondaryDynamicQueryFilterInfoSection: boolean = false;
  // secondaryDynamicQueryFilterInfoText: string = '';
  removeSecondaryDynamicQueryFilter: boolean = false;

  pageNameMap: any = {
    po_filters: 'Orders',
    shipment_filters: 'Shipments',
    dashboard_filters: 'Dashboard',
    alert_filters: 'Alert'
  }

  selectedSavedDynamicQueryFilters: number = -1;

  disableYesButton: boolean = true;

  query = {
    condition: 'and',
    rules: []
  };

  queryConfig: QueryBuilderConfig = {
    fields: {}
  };

  classNames: QueryBuilderClassNames = {
    removeIcon: 'fa fa-times',
    addIcon: 'fa fa-plus',
    arrowIcon: 'fa fa-chevron-right px-2',
    button: 'btn',
    buttonGroup: 'btn-group',
    rightAlign: 'order-12 ml-auto',
    switchRow: 'd-flex px-2',
    switchGroup: 'd-flex align-items-center',
    switchRadio: 'custom-control-input',
    switchLabel: 'custom-control-label',
    switchControl: 'custom-control custom-radio custom-control-inline',
    row: 'row p-2 m-1',
    rule: 'border',
    ruleSet: 'border',
    invalidRuleSet: 'alert alert-danger',
    emptyWarning: 'text-danger mx-auto',
    operatorControl: 'form-control',
    operatorControlSize: 'col-auto pr-0',
    fieldControl: 'form-control',
    fieldControlSize: 'col-auto pr-0',
    entityControl: 'form-control',
    entityControlSize: 'col-auto pr-0',
    inputControl: 'form-control',
    inputControlSize: 'col-auto'
  }

  constructor(
    private activeModal: NgbActiveModal,
    private metadataService: MetaDataService,
    private globalService: GlobalService,
    private toastrService: ToastrService,
    private userService: UserService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.loadAll();
  }

  loadAll() {
    if (this.filterCriteria.id != -1) {
      this.activatedRoute.queryParams.subscribe(params => {
        const redirectedFrom = params['redirected-from'];
        this.secondaryFilter = params['secondary-filter'] ? parseInt(params['secondary-filter']) : 0;
      });
      this.updateInfoPopoverText();
      this.getQueryBuilderConfig();
      if (!this.enableDynamicQueryFilterSave) {
        this.getSavedDynamicQueryFilter();
      }
    }
  }

  private updateInfoPopoverText() {
    this.infoPopoverText = this.globalService.dynamicFilterInfoPopoverTextMap['generic'];

    if (this.filterCriteria.id != -1) {
      this.infoPopoverText = this.globalService.dynamicFilterInfoPopoverTextMap[this.filterCriteria.type];
    }

  }

  private getSavedDynamicQueryFilter() {

    // API call to bring the saved query filter.
    const postData = {
      filterTypeId: this.filterCriteria.id
    }

    this.userService.getSavedDynamicQueryFilter(postData).subscribe({
      next: (res: any) => {
        this.savedDynamicQueryFilters = res.body.dynamic_query_filters;
      },
      error: (err) => {
        this.toastrService.error(err.error.message);
        console.log('Error', err);
      }
    });
  }

  private getQueryBuilderConfig(): void {

    // API call to bring the meta for the query builder.
    const postData = {
      filterTypeId: this.filterCriteria.id
    }
    this.metadataService.getDynamicQueryMetadata(postData).subscribe({
      next: (res: any) => {
        // console.log('res', res);
        this.globalService.dynamicQueryFilterMetaData = res.body;
        this.prepareQueryConfig(this.globalService.dynamicQueryFilterMetaData);
      },
      error: (err) => {
        this.toastrService.error(err.error.message);
        console.log('Error', err);
      }
    });
  }

  private getQueryFieldType(type: string): string {
    switch (type?.toLowerCase()) {
      case 'number':
        return type;
        break;
      case 'int':
        return 'number';
        break;
      case 'string':
        return type;
        break;
      case 'str':
        return 'string';
        break;
      case 'boolean':
        return type;
        break;
      case 'bool':
        return 'boolean';
        break;
      case 'date':
        return 'date';
        break;
      case 'category':
        return type;
        break;
      case 'multiselect':
        return type;
        break;
      default:
        return 'string';
        break;
    }
  }

  private getDefaultOperator(type: string): string {
    switch (type?.toLowerCase()) {
      case 'number':
        return '=';
        break;
      case 'string':
        return 'contains';
        break;
      case 'boolean':
        return 'true';
        break;
      case 'date':
        return 'earlier than';
        break;
      case 'multiselect':
        return 'is one of';
        break;
      default:
        return 'is';
        break;
    }
  }

  private getDefaultValue(type: string): string | number | boolean | Date {
    switch (type?.toLowerCase()) {
      case 'number':
        return 0;
        break;
      case 'string':
        return '';
        break;
      case 'boolean':
        return false;
        break;
      // case 'date':
      //   return this.globalService.getCurrentDate();
      //   break;
      default:
        return '';
        break;
    }
  }

  private prepareQueryConfig(data: Array<any>): void {

    // console.log('allSecondaryExists', this.allSecondaryQueryExistsInQueryConfig(), this.globalService.getDynamicFilterData());

    if (!this.allSecondaryQueryExistsInQueryConfig()) {
      this.showSecondaryDynamicQueryFilterInfoSection = true;
    }

    // Function to generate the configurations from meta for the query builder.
    const queryConfig: any = {
      fields: {},
    };

    data.forEach((item: any) => {
      queryConfig.fields[item.key] = {
        name: item.title,
        type: item.type,
        operators: !!item.operators ? [...item.operators] : [],
        options: !!item.options ? [...item.options] : [],
        defaultOperator: this.getDefaultOperator(this.getQueryFieldType(item.type)),
        defaultValue: this.getDefaultValue(this.getQueryFieldType(item.type)),
      };
    });

    this.queryConfig = queryConfig;
    this.setQuery();

  }

  allSecondaryQueryExistsInQueryConfig(): boolean {
    // Extract the list of keys from the dynamicQueryFilterMetaData array
    const metaDataKeys = this.globalService.dynamicQueryFilterMetaData.map(item => item.key);

    // Check if all the fields in secondary getDynamicFilterData().rules exist in the metaDataKeys
    if (Object.keys(this.globalService.getDynamicFilterData()).length > 0 && this.globalService.getDynamicFilterData().constructor === Object) {
      this.generateSecondaryDynamicQueryFilterInfoText();
      return this.globalService.getDynamicFilterData().rules.every((rule: any) => metaDataKeys.includes(rule.field));
    }

    return true;
  }

  private setQuery() {
    if (Object.keys(this.dynamicQueryFilters).length !== 0) {
      this.query = JSON.parse(JSON.stringify(this.dynamicQueryFilters));
    }
  }

  private clearQuery() {
    this.query = {
      condition: 'and',
      rules: []
    };

    this.queryConfig = {
      fields: {}
    };
  }

  public onQueryChange(event: any): void {
    this.disableSubmitButtonHandler(this.query.rules, this.dynamicQueryFilterName);
  }

  private disableSubmitButtonHandler(rules: Array<any>, dynamicQueryFilterName: string) {
    this.disableYesButton = true;

    if (!this.enableDynamicQueryFilterSave) {
      if (rules.length < 1) {
        this.disableYesButton = false;
      }
      if (rules.some(rule => rule.value !== '' && rule.value !== null)) {
        this.disableYesButton = false;
      }
    } else {
      if (rules.length > 0 && dynamicQueryFilterName !== '' && this.filterCriteria.id != -1) {
        if (rules.some(rule => rule.value !== '' && rule.value !== null)) {
          this.disableYesButton = false;
        }
      }
    }
  }

  onNo() {
    this.activeModal.dismiss('X');
  }

  onYes() {
    if (!this.enableDynamicQueryFilterSave) {
      if (this.removeSecondaryDynamicQueryFilter) {
        this.onRemoveSecondaryDynamicQueryFilter();
      }
      this.activeModal.close(this.query);
      return;
    }

    const saveDynamicQueryObj: any = {
      filterName: this.dynamicQueryFilterName,
      filter: this.query
    };

    if (this.savedDynamicQueryFilterObj?.id) {
      saveDynamicQueryObj.filterId = this.savedDynamicQueryFilterObj.id;
    } else {
      saveDynamicQueryObj.filterTypeId = this.filterCriteria.id;
    }

    if (!this.isSaveFilterNameDuplicate(saveDynamicQueryObj)) {
      this.activeModal.close(saveDynamicQueryObj);
    } else {
      this.toastrService.error('A Saved Filter with this name already exists.');
    }
  }

  isSaveFilterNameDuplicate(filter: any): boolean {
    return this.savedDynamicQueryFilters.some(savedFilter =>
      savedFilter.name === filter.filterName && savedFilter.id !== filter.filterId
    );
  }

  onDynamicQueryFilterNameChange(eventTarget: any) {
    this.dynamicQueryFilterName = eventTarget.value;
    this.disableSubmitButtonHandler(this.query.rules, this.dynamicQueryFilterName);
  }

  onFilterTypeChange(eventTarget: any) {
    this.updateInfoPopoverText()
    this.clearQuery();
    this.loadAll();
  }

  onSavedDynamicQueryFilterChange(eventTarget: any) {
    this.query = this.getSelectedSavedDynamicQueryFilter(this.selectedSavedDynamicQueryFilters).filter_values;
  }

  getSelectedSavedDynamicQueryFilter(id: number) {
    return this.savedDynamicQueryFilters.find((filter) => filter.id == id);
  }

  onRemoveSecondaryDynamicQueryFilterClick() {
    this.removeSecondaryDynamicQueryFilter = true;
    this.showSecondaryDynamicQueryFilterInfoSection = false;
  }

  onRemoveSecondaryDynamicQueryFilter() {
    // Extract the list of keys from the dynamicQueryFilterMetaData array
    const metaDataKeys = this.globalService.dynamicQueryFilterMetaData.map(item => item.key);

    // Filter out rules whose fields do not exist in metaDataKeys
    this.globalService.getDynamicFilterData().rules = this.globalService.getDynamicFilterData().rules.filter((rule: any) => metaDataKeys.includes(rule.field));
  }

  generateSecondaryDynamicQueryFilterInfoText(): string {
    // Collect relevant rule descriptions
    const descriptions = this.globalService.getDynamicFilterData().rules
      .filter((rule: any) => this.globalService.secondaryDynamicQueryFilterStringMap[rule.field]) // Check if rule field exists in the map
      .map((rule: any) => {
        const uniqueValuesCount = new Set(rule.value).size; // Count unique values
        const fieldLabel = this.globalService.secondaryDynamicQueryFilterStringMap[rule.field];
        return `${uniqueValuesCount} ${fieldLabel}`;
      });
    
    // console.log('descriptions', descriptions);
    

    // Build the information text
    if (descriptions.length > 0) {
      return `Some filters are included by default, corresponding to ${descriptions.join(', ')}.`;
    } else {
      return '';
    }
  }

}
