import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { NgbModal, NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';

import { PaginationData } from '../../service/global.service';
import { ColumnFilterModalComponent } from '../column-filter-modal/column-filter-modal.component';
import { DynamicQueryModalComponent } from '../dynamic-query-modal/dynamic-query-modal.component';

@Component({
  selector: 'rc-generic-grid-template',
  templateUrl: './generic-grid-template.component.html',
  styleUrls: ['./generic-grid-template.component.css']
})
export class GenericGridTemplateComponent implements OnInit {
  @Input() pageLabel: string = '';
  @Input() dynamicQueryFilterDescription: string = '';
  @Input() pageFilterType: string = '';
  @Input() noGridDataMessage: string = '';
  @Input() gridData: Array<any> = [];
  @Input() showBulkActionButton: boolean = false;
  @Input() showTitleHeaderForTable: boolean = false;
  @Input() showCheckboxColumn: boolean = false;
  @Input() showActionsColumn: boolean = false;
  @Input() showDynamicQueryButton: boolean = false;
  @Input() columnMappings: Array<any> = [];
  @Input() gridHeaderList: Array<string> = [];
  @Input() gridColumnList: Array<string> = [];
  @Input() total: number = 0;
  @Input() paginationData: PaginationData = {
    currentPage: 1,
    pageSize: 10,
    sortOrder: '-1',
    sortColumn: 'id',
  };
  @Input() filterCriteria: any = { id: -1, type: 'Data Type...' };
  @Input() columnFilters: Array<string> = [];
  @Input() defaultColumnFilters: Array<string> = [];
  @Input() disabledColumns: Array<string> = [];
  @Input() currencyColumns: Array<string> = [];
  @Input() selectedGridData: Array<string> = [];
  @Input() selectedGridDataCountText: string = '';
  @Input() totalGirdDataCountText: string = '';
  @Input() selectTotalGridData: boolean = false;
  @Input() showTimelineColumn: boolean = false;
  @Input() activateCellClick: boolean = false;
  @Input() cellClickColumnList: Array<string> = [];
  @Input() dynamicQueryFilters: any = {};
  @Input() showExpandedModeToggleSection: boolean = false;
  @Input() expandedModeLabel: string = '';
  @Input() expandedMode: boolean = true;
  @Input() valueFormatMap: any = {};

  @Output() paginationDataChanged = new EventEmitter<any>();
  @Output() columnFilterChanged = new EventEmitter<any>();
  @Output() selectedGridDataChanged = new EventEmitter<Array<string>>();
  @Output() selectTotalGridDataChanged = new EventEmitter<boolean>();
  @Output() viewTimelineClicked = new EventEmitter<any>();
  @Output() tableCellClicked = new EventEmitter<any>();
  @Output() dynamicQueryEmitter = new EventEmitter<any>();


  sortColumnKey: string = '';

  selectAllCurrentPageGridData: boolean = false;
  showSelectedGridDataCountText: boolean = false;

  showTotalGirdDataCountText: boolean = false;
  showClearSelection: boolean = false;

  constructor(
    private modalService: NgbModal,
    config: NgbPaginationConfig
  ) {
    config.size = 'sm';
    config.boundaryLinks = true;
  }

  ngOnInit(): void {
    if (this.gridData.length > 0) {
      this.gridData = this.gridData.map(item => ({ ...item, isExpanded: false }));
      this.updateGridDataSelectionMessageState();
      this.updateSelectAllState();
      // this.toggleActionsColumn();
    }
  }

  loadAll() {
    this.toggleSelectTotalGridData(false);
    this.updateGridDataSelectionMessageState();
    this.updateSelectAllState();
    // this.toggleActionsColumn();

    this.paginationDataChanged.emit(this.paginationData)
  }

  isDate(value: any): boolean {
    const dateFormats = [
      /^\d{4}-\d{2}-\d{2}$/, // YYYY-MM-DD
      /^\d{2}\/\d{2}\/\d{4}$/, // MM/DD/YYYY
      /^\d{4}\/\d{2}\/\d{2}$/ // YYYY/MM/DD
    ];

    if (typeof value === 'string') {
      for (const format of dateFormats) {
        if (format.test(value)) {
          // Check if the date is valid
          const date = new Date(value);
          return !isNaN(date.getTime());
        }
      }
    }

    return false;
  }

  isCurrency(value: any): boolean {    
    return this.currencyColumns.includes(value);
  }

  isNumber(value: any): boolean {    
    return typeof value == 'number' ? true : false;
  }

  formatValues(value: string): string{
    return this.valueFormatMap[value]
  }

  get startItemCount(): number {
    return (this.paginationData.currentPage - 1) * this.paginationData.pageSize + 1;
  }

  get endItemCount(): number {
    return Math.min(this.paginationData.currentPage * this.paginationData.pageSize, this.total);
  }

  isSelected(item: any): boolean {
    return this.selectedGridData.some((selectedItem: any) => selectedItem.id === item.id);
  }

  onSelectAllCurrentPageGridData() {
    // On this.selectAllCurrentPageGridData is false when it is checked and true when unchecked.

    if (this.selectAllCurrentPageGridData) {
      this.selectedGridData = [];
    } else {
      this.selectedGridData = [...this.gridData];
    }

    this.updateGridDataSelectionMessageState();
    this.updateSelectAllState();
    // this.toggleActionsColumn();
    this.selectedGridDataChanged.emit(this.selectedGridData);
  }

  updateSelectAllState(): void {
    const selectedIds = new Set(this.selectedGridData.map((item: any) => item.id));
    this.selectAllCurrentPageGridData = this.gridData.every(item => selectedIds.has(item.id));

    if (this.selectAllCurrentPageGridData) {
      this.toggleSelectTotalGridData(false);
    }

    this.updateGridDataSelectionMessageState();
  }

  toggleSelectTotalGridData(selectTotal: boolean) {
    this.selectTotalGridData = selectTotal;
    this.selectTotalGridDataChanged.emit(this.selectTotalGridData);
  }

  onSelectTotalGridData(): void {
    this.toggleSelectTotalGridData(true);

    this.updateGridDataSelectionMessageState();
  }

  updateGridDataSelectionMessageState(): void {
    this.showSelectedGridDataCountText = this.selectedGridData.length > 0;
    this.showTotalGirdDataCountText = this.selectAllCurrentPageGridData && !this.selectTotalGridData;
    this.showClearSelection = this.selectedGridData.length > 0 && this.selectTotalGridData;
  }

  toggleSelectedRows(item: any) {
    const index = this.selectedGridData.findIndex((selectedItem: any) => selectedItem.id === item.id);

    if (index !== -1) {
      this.selectedGridData.splice(index, 1);
    } else {
      this.selectedGridData.push(item);
    }

    this.toggleSelectTotalGridData(false);
    this.updateGridDataSelectionMessageState();
    this.updateSelectAllState();
    // this.toggleActionsColumn();
    this.selectedGridDataChanged.emit(this.selectedGridData);
  }

  onClearSelection(): void {
    this.selectedGridData = [];

    this.toggleSelectTotalGridData(false);
    this.updateGridDataSelectionMessageState();
    this.updateSelectAllState();
    // this.toggleActionsColumn();
    this.selectedGridDataChanged.emit(this.selectedGridData);
  }

  getSortColumnKey(columnValue: string): string {
    const key = Object.keys(this.columnMappings).find((key: any) => this.columnMappings[key] === columnValue);
    return key ? key : '';
  }

  sortGridData(column: string) {
    this.paginationData.sortColumn = this.getSortColumnKey(column);
    this.paginationData.sortOrder = this.paginationData.sortOrder == '-1' ? '1' : '-1';
    this.loadAll();
  }

  onPageSizeChange(selectedValue: string): void {
    this.paginationData.pageSize = parseInt(selectedValue);
    this.paginationData.currentPage = 1;
    this.loadAll();
  }

  onPaginationChange(event: any) {
    this.paginationData.currentPage = event;
    this.loadAll();
  }

  openColumnFilterModal() {
    const modalRef = this.modalService.open(ColumnFilterModalComponent);
    modalRef.componentInstance.columnMappings = this.columnMappings;
    modalRef.componentInstance.columnFilters = this.columnFilters;
    modalRef.componentInstance.defaultColumnFilters = this.defaultColumnFilters;
    modalRef.componentInstance.disabledColumns = this.disabledColumns;
    modalRef.componentInstance.showExpandedModeToggleSection = this.showExpandedModeToggleSection;
    modalRef.componentInstance.expandedModeLabel = this.expandedModeLabel;
    modalRef.componentInstance.expandedMode = this.expandedMode;
    modalRef.componentInstance.yesText = 'Save';
    modalRef.componentInstance.resetToDefaultText = 'Reset'

    modalRef.result.then(result => {
      if ('X' !== result) {
        this.columnFilterChange(result);
      }
    }).catch(error => {
    });
  }

  columnFilterChange(data: any) {
    this.columnFilterChanged.emit(data);
  }

  // toggleActionsColumn() {
  //   if (this.selectedGridData.length > 1) {
  //     this.showActionsColumn = false;
  //   } else {
  //     this.showActionsColumn = true;
  //   }
  // }

  onViewTimeline(shipmentRowData: any) {
    this.viewTimelineClicked.emit(shipmentRowData);
  }

  onCellClickHandler(field: string, rowData: any) {
    if (this.cellClickColumnList.includes(field)) {
      this.tableCellClicked.emit({ field, rowData });
    }
  }

  openDynamicQueryModal() {
    const modalRef = this.modalService.open(DynamicQueryModalComponent, { size: 'lg' });
    modalRef.componentInstance.pageLabel = this.pageLabel;
    modalRef.componentInstance.dynamicQueryFilterDescription = this.dynamicQueryFilterDescription;
    modalRef.componentInstance.noText = 'Cancel';
    modalRef.componentInstance.yesText = 'Apply Filters';
    modalRef.componentInstance.enableDynamicQueryFilterSave = false;
    modalRef.componentInstance.filterCriteria = this.filterCriteria;
    modalRef.componentInstance.dynamicQueryFilters = this.dynamicQueryFilters;
    modalRef.result.then(query => {
      if ('X' !== query) {
        this.onClearSelection();
        this.dynamicQueryFilters = query;
        this.dynamicQueryEmitter.emit({ paginationData: this.paginationData, dynamicQueryFilters: this.dynamicQueryFilters });
      }
    }).catch(error => {
      console.log(error);
    });
  }

  shouldExpandRow(item: any): boolean {
    return this.expandedMode && item.items && item.items.length > 1;
  }
  
}
