<ngx-spinner bdColor="rgba(51,51,51, 0.8)" color="#fff" [fullScreen]="true" size="medium">
</ngx-spinner>

<div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">
        Select a subscriber
    </h5>
</div>
<div class="modal-body">
    <div class="card-body">
        <select class="form-select mb-3" id="subscriberDropdown" [(ngModel)]="selectedSubscriberId">
            <option [value]="-1" hidden>Select Subscriber</option>
            <option *ngFor="let subscriber of subscribers" [value]="subscriber.id">{{ subscriber.name }}</option>
        </select>
        <button type="button" id="formbtn" class="btn btn-primary" [disabled]="selectedSubscriberId === -1"
            (click)="onConfirm()">Confirm</button>
    </div>
</div>

<!-- <div class="height-80 main-panel"> -->
<!-- <div class="modal-body custom-modal-size">
        <div class="card bg-light" style="border: none">
            <section class="section-access">
                <div class="container h-custom">
                    <div class="row d-flex justify-content-center align-items-center h-100">
                        <div id="custom-container" class="container d-flex justify-content-center">
                            <div id="custom-card" class="card kpi-card bg-light text-center">
                                <div class="card-body">
                                    <label for="subscriberDropdown" class="form-label">Select a subscriber</label>
                                    <select class="form-select mb-3" id="subscriberDropdown" [(ngModel)]="selectedSubscriberId">
                                        <option [value]="-1" hidden>Select Subscriber</option>
                                        <option *ngFor="let subscriber of subscribers" [value]="subscriber.id">{{ subscriber.name }}</option>
                                    </select>
                                    <button type="button" id="formbtn" class="btn btn-primary"
                                        [disabled]="selectedSubscriberId === -1" (click)="onConfirm()">Confirm</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div> -->

<!-- </div> -->