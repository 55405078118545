<ngx-spinner bdColor="rgba(51,51,51, 0.8)" color="#fff" [fullScreen]="true" size="medium"></ngx-spinner>
<!-- <div id="myModal" style="display: block;">
    <div class="modal-dialog">
        <div class="modal-content"> -->

<div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">
        Create a New User
    </h5>
    <button type="button" class="btn-close-custom" (click)="activeModal.dismiss('X')">
        <img src="assets/images/cross_white.png" alt="Close">
    </button>
</div>
<div class="modal-body">
    <form id="createEntityForm" [formGroup]="addUserForm" (ngSubmit)="onSubmit()" autocomplete="off">
        <div class="mb-3">
            <label for="email" class="form-label">User Email:</label>
            <input type="text" id="email" name="email" placeholder="Enter a valid email address *" class="form-control"
                formControlName="email" [ngClass]="{ 'is-invalid': submitted && f['email'].errors }" />
            <div *ngIf="submitted && f['email'].errors" class="invalid-feedback">
                <div *ngIf="f['email'].errors['required']">User email is required</div>
                <div *ngIf="f['email'].errors['email']">Email is invalid</div>
            </div>
        </div>

        <div class="mb-3" *ngIf="user.subscriber_type != 'RESICHAIN'">
            <label for="role" class="form-label">User Role:</label>
            <select name="role" id="role" class="form-control form-control-lg" style="appearance: auto;"
                formControlName="role" [ngClass]="{ 'is-invalid': submitted && f['role'].errors }">
                <option [value]="-1" hidden>Select User Role</option>
                <option *ngFor="let role of roles" [value]="role.role">
                    {{role.role}}
                </option>
            </select>
            <div *ngIf="submitted && f['role'].errors" class="invalid-feedback">
                <div *ngIf="f['role'].errors['required']">This field is required</div>
            </div>
        </div>

        <button type="submit" class="btn btn-primary btn-lg" id="modal-button">
            Register User
        </button>
    </form>
</div>

<!-- </div>
    </div>
</div> -->