<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">
    Select columns to filter
  </h5>
  <button type="button" class="btn-close-custom" (click)="onNo()">
    <img src="assets/images/cross_white.png" alt="Close">
  </button>
</div>
<div class="modal-body">
  <div class="options-list" id="testOptions">
    <div class="option-row">
      <div class="option-item" *ngFor="let key of columnMappingKeys">
        <input type="checkbox" [disabled]="isDisabled(key)" [checked]="isSelected(key)" (change)="toggleSelection(key)">
        <label>{{ columnMappings[key] }}</label>
      </div>
    </div>

    <hr *ngIf="showExpandedModeToggleSection">

    <div class="option-row" *ngIf="showExpandedModeToggleSection">
      <input type="checkbox" [(ngModel)]="expandedMode" (change)="toggleExpandedMode($event)">
      <label>{{ expandedModeLabel }} <i class="fa fa-info-circle fa-lg" aria-hidden="true" id="iButton"
          title={{expandedModeLabel}}></i></label>
    </div>

    <div class="modal-footer">
      <button type="button" class="btn btn-primary" id="confirmationButton" (click)="onResetToDefault()"
        style="width:22%">{{resetToDefaultText}}</button>
      <button type="button" class="btn btn-primary" id="confirmationButton" (click)="onYes()"
        [disabled]="selectedColumnFilterCount <= 1" style="width:22%">{{yesText}}</button>
    </div>
  </div>
</div>