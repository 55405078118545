import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalService } from '../../service/global.service';
import { AuthService } from '../../service/auth.service';
import { AccountService } from '../../service/account.service';
import { Router } from '@angular/router';
import { Account } from '../../model/account.model';
import { ChangePasswordModalComponent } from '../change-password-modal/change-password-modal.component';
import { UserService } from '../../service/user.service';


@Component({
  selector: 'rc-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  public user: any = null;
  isExpanded: boolean;
  menuVisible = false;

  latestFileIngestionDateMessage: string = '';
  isInjestionDateLatest: boolean = true;

  constructor(
    private globalService: GlobalService,
    private authService: AuthService,
    private accountService: AccountService,
    private router: Router,
    private modalService: NgbModal,
    private userService: UserService
  ) {
    accountService.loginEmitter.subscribe(e => this.setCurrentUser(e));
    accountService.subscriberChangeEmitter.subscribe(e => this.getCurrentUser());
    authService.logoutEmitter.subscribe(e => this.setCurrentUser(e));
    this.isExpanded = localStorage.getItem('sidebarExpanded') === 'true';
  }
 

 
  ngOnInit(): void {
    this.loadAll();
  }

  loadAll() {
    this.getCurrentUser();
    this.toggleBodyPadding();
  }

  getCurrentUser() {
    if (this.authService.getToken()) {
      this.accountService.fetch().subscribe({
        next: (res) => {
          this.globalService.user = res;
          this.setCurrentUser(res);
          this.getFileRefreshLatestDate();
        }, error: (err) => {
          console.log("Error", err)
        }, complete: () => { }
      })
    }
  }

  setCurrentUser(e: Account) {
    this.user = e;
  }

  getFileRefreshLatestDate() {
    this.userService.getFileRefreshLatestDate().subscribe({
      next: (res: any) => {
        // console.log("res", res);
        const today = new Date();
        const fileRefreshLatestDate = new Date(res.body.file_refresh_latest_date);
        const sevenDaysInMillis = 7 * 24 * 60 * 60 * 1000;
        const formattedFileRefreshLatestDate = fileRefreshLatestDate.toLocaleDateString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
        });
        this.latestFileIngestionDateMessage = "Data last refreshed: " + formattedFileRefreshLatestDate;
        this.isInjestionDateLatest = (today.getTime() - fileRefreshLatestDate.getTime()) <= sevenDaysInMillis;
      },
      error: (err) => {
        console.log("Error", err);
      }
    });
  }

  goToDashboard() {
    this.router.navigateByUrl('/dashboard');
  }

  openChangePasswordModal() {
    const modalRef = this.modalService.open(ChangePasswordModalComponent);
    modalRef.componentInstance.userEmail = this.user.email;

    modalRef.result.then(result => {
      this.onLogout();
    }).catch(error => {
      console.log('Error', error)
    });
  }

  onLogout() {
    this.authService.logout().subscribe();
  }

  toggleSidebar() {
    this.isExpanded = !this.isExpanded;
    localStorage.setItem('sidebarExpanded', this.isExpanded ? 'true' : 'false');
    this.toggleBodyPadding(); 
  }
  toggleBodyPadding() {
    const bodyElement = document.body;
    if (this.isExpanded) {
      bodyElement.classList.add('body-pd');
    } else {
      bodyElement.classList.remove('body-pd');
    }
  }

  toggleMenu() {
    this.menuVisible = !this.menuVisible; 
  }
  
    // toggleSidebar() {
  //   this.isExpanded = !this.isExpanded;
  // }
  
  // onMouseEnter() {
  //   this.showDetailedStyle = true;
  // }

  // onMouseLeave() {
  //   this.showDetailedStyle = false;
  // }

}
