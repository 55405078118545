import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalService } from '../../service/global.service';
import { IngestionService } from '../../service/ingestion.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'rc-file-upload-mappings',
  templateUrl: './file-upload-mappings.component.html',
  styleUrls: ['./file-upload-mappings.component.css']
})
export class FileUploadMappingsComponent implements OnInit {
  loading: boolean = false;

  @Input() noText: string = '';
  @Input() yesText: string = '';
  @Input() nextText: string = '';
  @Input() flagFileName: string = '';
  @Input() selectedDataType: any = null;
  @Input() editMode: boolean = false;

  threeStepsArrayObj: Array<any> = [
    {
      step: 1,
      stepTitle: 'Upload File'
    },
    {
      step: 2,
      stepTitle: 'Map Data'
    },
    {
      step: 3,
      stepTitle: 'Review'
    }
  ];

  currentStepObj: any = {
    step: 1,
    stepTitle: 'Upload File'
  }

  dataTypeMap: any;
  mappingObjectTypes: Array<any> = [];

  addedFiles: File[] = [];
  uploadedFiles: Array<string> = [];

  dataMappingName: string = '';
  dataDescriptionMappings: Array<any> = [];

  fileColumns: Array<string> = [];

  fileStatuses: Array<any> = [];

  containsFlagFile: boolean = false;
  warningText: string = `Please check if all the file you want to upload are added before uploading the ${this.flagFileName} file.`;
  showWarningText: boolean = false;

  showFilePreview: boolean = false;
  fileContent: any;

  constructor(
    private globalService: GlobalService,
    private ingestionService: IngestionService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    public activeModal: NgbActiveModal
  ) { 
    this.dataTypeMap = this.globalService.dataTypeMap;
  }

  ngOnInit(): void {
    this.loadAll();
  }

  loadAll() {
    this.getMappingObjectTypes();
  }

  getMappingObjectTypes() {
    this.ingestionService.getAllMappingObjectTypes().subscribe({
      next: (res: any) => {
        this.mappingObjectTypes = res.body.mapping_object_types;
        // console.log('res', res);
      },
      error: (err) => {
        this.toastr.error(err.error.message);
        console.log("Error", err);
      }
    })
  }

  objectKeys(obj: any): string[] {
    return Object.keys(obj);
  }

  isButtonsDisabled(step: number): boolean {
    if (step === 1) {
      if (this.selectedDataType === null || this.addedFiles.length < 1) {
        return true;
      }

      if ((this.selectedDataType === 'custom' && this.dataMappingName === '') || this.addedFiles.length < 1) {
        return true;
      }
    }

    if (step === 2) {
      // Placeholder for adding validation (no validations needed for now.) 
    }

    return false;
  }

  updateCurrentStep(step: number): void {
    this.currentStepObj = this.threeStepsArrayObj.find(stepObj => stepObj.step == step + 1);
  }

  chooseFile(): void {
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    fileInput.click();
  }

  onFileAdd(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const files = Array.from(input.files);
      this.handleFiles(files);
    }
  }

  clearFiles(): void {
    this.addedFiles = [];
  }

  onDrop(event: DragEvent): void {
    event.preventDefault();
    const files = Array.from(event.dataTransfer?.files || []);
    this.handleFiles(files);
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
    const uploadBox = event.target as HTMLElement;
    uploadBox.classList.add('dragover');
  }

  onDragLeave(event: DragEvent): void {
    event.preventDefault();
    const uploadBox = event.target as HTMLElement;
    uploadBox.classList.remove('dragover');
  }

  handleFiles(files: File[]): void {
    const validFormats = ['csv', 'xlsx', 'xls'];
    const maxFileSizeMB = 25;
    const invalidFiles = files.filter(file => {
      // console.log('file', file);
      const extension = file.name.split('.').pop()?.toLowerCase();
      const isFlagFile = file.name === this.flagFileName;
      if (isFlagFile) {
        this.containsFlagFile = true;
        return false; // Do not mark the flagFile as invalid, regardless of extension
      }

      return !extension || !validFormats.includes(extension) || file.size > maxFileSizeMB * 1024 * 1024;
    });

    if (invalidFiles.length > 0) {
      this.toastr.error(invalidFiles.length + ' file(s) have invalid format or exceed the size limit.');
    } else {
      this.addedFiles = files;
      console.log(this.addedFiles);
    }
    // Set showWarningText to true if flagFile is present
    this.showWarningText = this.containsFlagFile;
  }

  onDataSourceChange(data: any, eventTarget: any) {
    const selectedValue = eventTarget.value;
    data.file_column = selectedValue !== '-1' ? selectedValue : null;

    // console.log('onDataSourceChange', data, selectedValue, this.dataDescriptionMappings);
  }

  removeMapping(data: any) {
    data.file_column = null;
  }

  changeStep(stepObj: any): void {
    if (this.editMode && this.currentStepObj.step != stepObj.step && this.uploadedFiles.length < 1 && (stepObj.step === 1 || stepObj.step === 2)) {

      if (stepObj.step === 1) {
        this.updateCurrentStep(stepObj.step - 1);
      } else if (stepObj.step === 2) {
        this.spinner.show();

        const postBody = {
          mappingObjectType: this.selectedDataType
        }
        
        if (this.dataDescriptionMappings.length > 0 && this.fileColumns.length > 0) {
          this.updateCurrentStep(stepObj.step - 1);
          this.spinner.hide();
          return  
        }

        this.ingestionService.getFileColumnMappingDataFromColumnFlavour(postBody).subscribe({
          next: (res: any) => {
            this.spinner.hide();
            // console.log('res', res);
            this.dataDescriptionMappings = res.body.mappings;
            this.fileColumns = res.body.file_columns;
            // console.log('dataDescriptionMappings', this.dataDescriptionMappings);
            // console.log('fileColumns', this.fileColumns);
            this.toastr.success('File(s) uploaded successfully.');
            this.updateCurrentStep(stepObj.step-1);
          },
          error: (err) => {
            this.spinner.hide();
            this.toastr.error(err.error.message);
            console.log('err', err);
          }
        })
      }
      // console.log('Step clicked:', stepObj, this.currentStepObj);
    }
  }

  // isDate(value: any): boolean {
  //   const dateFormats = [
  //     /^\d{4}-\d{2}-\d{2}$/, // YYYY-MM-DD
  //     /^\d{2}\/\d{2}\/\d{4}$/, // MM/DD/YYYY
  //     /^\d{4}\/\d{2}\/\d{2}$/ // YYYY/MM/DD
  //   ];

  //   if (typeof value === 'string') {
  //     for (const format of dateFormats) {
  //       if (format.test(value)) {
  //         // Check if the date is valid
  //         const date = new Date(value);
  //         return !isNaN(date.getTime());
  //       }
  //     }
  //   }

  //   return false;
  // }

  onNo() {
    this.activeModal.dismiss('X');
  }

  onSaveAndExit(step: number) {
    this.loading = true;

    this.spinner.show();

    if (step == 1) {
      const formData = new FormData();
      this.addedFiles.forEach(file => formData.append('files', file, file.name));

      formData.append('mappingObjectType', this.selectedDataType);
      this.ingestionService.upload(formData).subscribe({
        next: (res: any) => {
          this.spinner.hide();
          this.toastr.success(res.body.message);
          this.activeModal.close('Y');
          // console.log('res', res);
        }, error: (err) => {
          this.spinner.hide();
          this.toastr.error(err.error.message);
          console.log('Error', err);
        }
      })
    } else if (step == 2) {
      if (this.editMode) {
        const postBody = {
          mappings: this.dataDescriptionMappings,
          mappingObjectType: this.selectedDataType
        }

        this.ingestionService.saveColumnMappingDataWithoutUploadedFiles(postBody).subscribe({
          next: (res: any) => {
            this.spinner.hide();
            // console.log('res', res);
            this.fileStatuses = res.body.file_statuses;
            this.updateCurrentStep(step);
            // console.log('fileStatuses', this.fileStatuses);
            this.toastr.success('Mappings Saved');
            this.activeModal.close('Y');
          },
          error: (err) => {
            this.spinner.hide();
            this.toastr.error(err.error.message);
            console.log('err', err);
          }
        })
      } else {
        const postBody = {
          mappings: this.dataDescriptionMappings,
          mappingObjectType: this.selectedDataType,
          uploadedFiles: this.uploadedFiles
        }

        this.ingestionService.saveColumnMappingData(postBody).subscribe({
          next: (res: any) => {
            this.spinner.hide();
            // console.log('res', res);
            this.fileStatuses = res.body.file_statuses;
            this.updateCurrentStep(step);
            // console.log('fileStatuses', this.fileStatuses);
            this.toastr.success('Mappings Saved');
            this.activeModal.close('Y');
          },
          error: (err) => {
            this.spinner.hide();
            this.toastr.error(err.error.message);
            console.log('err', err);
          }
        })
      }
    } else if (step == 3) {
      this.spinner.hide();
      this.activeModal.close('Y');
    }

  }

  onNext(step: number) {
    this.loading = true;

    this.spinner.show();

    if (step == 1) {
      const formData = new FormData();
      this.addedFiles.forEach(file => formData.append('files', file, file.name));

      formData.append('mappingObjectType', this.selectedDataType);
      // console.log('formData', formData);

      this.ingestionService.getFileColumnMappingData(formData).subscribe({
        next: (res: any) => {
          this.spinner.hide();
          // console.log('res', res);
          this.dataDescriptionMappings = res.body.mappings;
          this.fileColumns = res.body.file_columns;
          this.uploadedFiles = res.body.uploaded_files;
          // console.log('dataDescriptionMappings', this.dataDescriptionMappings);
          // console.log('fileColumns', this.fileColumns);
          this.toastr.success('File(s) uploaded successfully.');
          this.updateCurrentStep(step);
        },
        error: (err) => {
          this.spinner.hide();
          this.toastr.error(err.error.message);
          console.log('err', err);
        }
      })
    } else if (step == 2) {
      const postBody = {
        mappings: this.dataDescriptionMappings,
        mappingObjectType: this.selectedDataType,
        uploadedFiles: this.uploadedFiles
      }

      this.ingestionService.saveColumnMappingData(postBody).subscribe({
        next: (res: any) => {
          // console.log('res', res);
          this.toastr.success('Mappings saved. Fetching uploaded file(s) metadata');

          const postBody = {
            fileColumns: this.fileColumns,
            mappings: this.dataDescriptionMappings,
            uploadedFiles: this.uploadedFiles
          }
          this.ingestionService.getSubscriberUploadedFilesMetadata(postBody).subscribe({
            next: (res: any) => {
              this.spinner.hide();
              this.fileStatuses = res.body;
              // console.log('fileStatuses', this.fileStatuses, res);
              this.updateCurrentStep(step);
            },
            error: (err) => {
              this.spinner.hide();
              this.toastr.error(err.error.message);
              console.log('err', err);
            }
          })
        },
        error: (err) => {
          this.spinner.hide();
          this.toastr.error(err.error.message);
          console.log('err', err);
        }
      })
    }
  }

  fileContentPreview(fileName: string) {
    this.spinner.show();
    const postBody = {
      fileName: fileName
    }
    this.ingestionService.fileContentPreview(postBody).subscribe({
      next: (res: any) => {
        console.log('res', res);
        this.fileContent = res.body;
        this.showFilePreview = true;
        this.spinner.hide();
      },
      error: (err) => {
        console.log('err', err);
        this.spinner.hide();
      }
    })
  }

}
