import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService } from '../service/auth.service';

/**
 * @whatItDoes Conditionally includes an HTML element if current user has any
 * of the authorities passed as the `expression`.
 *
 * @howToUse
 * ```
 *     <some-element *rcHasAnyAuthority="'RESICHAIN_EMPLOYEE'">...</some-element>
 *
 *     <some-element *rcHasAnyAuthority="['RESICHAIN_EMPLOYEE', 'ADMIN']">...</some-element>
 * ```
 */

@Directive({
  selector: '[rcHasAnyAuthority]'
})
export class HasAnyAuthorityDirective {

  private authorities: string[] = [];

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef,
    private authService: AuthService
  ) { }

  @Input()
  set rcHasAnyAuthority(value: string | string[]) {

    this.authorities = typeof value === 'string' ? [value] : value;


    this.updateView();
  }

  private updateView(): void {
    const hasAnyAuthority = this.authorities.includes(this.authService.getRole());
    this.viewContainerRef.clear();
    if (hasAnyAuthority) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    }

  }

}
