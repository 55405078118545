import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'rc-column-filter-modal',
  templateUrl: './column-filter-modal.component.html',
  styleUrls: ['./column-filter-modal.component.css']
})
export class ColumnFilterModalComponent implements OnInit {

  @Input() yesText: string = '';
  @Input() resetToDefaultText: string = '';
  @Input() columnMappings: any;
  @Input() columnFilters: Array<string> = [];
  @Input() defaultColumnFilters: Array<string> = [];
  @Input() disabledColumns: Array<string> = [];
  @Input() showExpandedModeToggleSection: boolean = false;
  @Input() expandedModeLabel: string = '';
  @Input() expandedMode: boolean = true;

  columnMappingKeys: Array<string> = [];

  selectedColumnFilterCount: number = 1;

  constructor(
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
    this.columnMappingKeys = Object.keys(this.columnMappings);
    this.selectedColumnFilterCount = this.columnFilters.length;
  }

  toggleSelection(key: string): void {
    const index = this.columnFilters.indexOf(key);
    if (index !== -1) {
      this.columnFilters.splice(index, 1);
    } else {
      this.columnFilters.push(key);
    }
    this.selectedColumnFilterCount = this.columnFilters.length;
  }

  toggleExpandedMode(event: Event): void {
    const checkbox = event.target as HTMLInputElement;
    this.expandedMode = checkbox.checked;
    // console.log('Checkbox status:', this.expandedMode);
  }

  isDisabled(key: string): boolean {
    return this.disabledColumns.includes(key);
  }

  isSelected(key: string): boolean {
    return this.columnFilters.includes(key);
  }

  onResetToDefault() {
    const result = {
      columnFilters: this.defaultColumnFilters,
      expandedMode: this.expandedMode
    }

    this.activeModal.close(result);
  }

  onYes() {
    const result = {
      columnFilters: this.columnFilters,
      expandedMode: this.expandedMode
    }
    this.activeModal.close(result);
  }

  onNo() {
    this.activeModal.dismiss('X');
  }  

}
