<div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">
        Filters
    </h5>
    <button type="button" class="btn-close-custom" (click)="onNo()">
        <img src="assets/images/cross_white.png" alt="Close">
    </button>
</div>
<div class="modal-body">
    <div class="card-body">
        <div class="mb-3">
            <div id="labelDynamicQuery" *ngIf="enableDynamicQueryFilterSave">
                <label for="filterCriteria" id="DQlabel">Select the type of data to be filtered:</label>
                <div style="position: relative; display: inline-block;">
                    <select [(ngModel)]="filterCriteria" name="filterCriteria"
                        [disabled]="savedDynamicQueryFilterObj?.id" class="form-control"
                        style="border: 1px solid #ced4da; min-width: 150px; max-width: 300px; width: auto;"
                        (change)="onFilterTypeChange($event.target)">
                        <option selected [value]="defaultFilterType" hidden>{{defaultFilterType.type}}</option>
                        <option *ngFor="let filterType of filterTypes" [ngValue]="filterType">
                            {{filterTypesMap[filterType.type]}}
                        </option>
                    </select>
                </div>
            </div>


            <div class="filter-instructions" *ngIf="!enableDynamicQueryFilterSave" id="ordersDQLabel">
                <span style="margin-right: 10px;">{{dynamicQueryFilterDescription}}</span>
                <div style="position: relative; display: inline-block;"
                    *ngIf="filterCriteria.type != 'dashboard_filters' && filterCriteria.type != 'alert_filters'">
                    <select [(ngModel)]="selectedSavedDynamicQueryFilters" name="selectedSavedDynamicQueryFilters"
                        class="form-control" id="ordersDQ" (change)="onSavedDynamicQueryFilterChange($event.target)">
                        <option selected [value]="-1" hidden>Saved Filters...</option>
                        <option *ngFor="let savedDynamicQueryFilter of savedDynamicQueryFilters"
                            [value]="savedDynamicQueryFilter.id">
                            {{savedDynamicQueryFilter.name}}
                        </option>
                    </select>
                </div>
            </div>

            <div *ngIf="showSecondaryDynamicQueryFilterInfoSection && secondaryFilter==1">
                <span style="color: red;">
                    <i class="fa fa-info-circle fa-lg" aria-hidden="true" title={{secondaryDynamicQueryFilterInfoPopoverText}}></i>
                    {{generateSecondaryDynamicQueryFilterInfoText()}} &nbsp;
                    <button class="btn btn-primary" (click)="onRemoveSecondaryDynamicQueryFilterClick()">
                        Remove pre-applied filters
                    </button></span>
            </div>

            <div class="filter-instructions" *ngIf="enableDynamicQueryFilterSave && this.filterCriteria.id != -1">
                <span>Create your saved filter by adding conditions below.</span>
            </div>
            <query-builder style="margin-bottom: 30px;" *ngIf="this.filterCriteria.id != -1" [(ngModel)]='query'
                [config]='queryConfig' [allowRuleset]="false" (ngModelChange)="onQueryChange($event)">
                <ng-container
                    *queryButtonGroup="let ruleset; let addRule=addRule; let allowRuleset=allowRuleset; let removeRuleSet=removeRuleSet">
                    <button type="button" class="add-condition-button" (click)="addRule()">
                        <i class="fa fa-plus"></i> Condition
                    </button>

                    <!-- <button type="button" *ngIf="allowRuleset" (click)="allowRuleset()"><i class="fa fa-plus"></i>
                        Condition Set</button>
                    <button type="button" *ngIf="removeRuleSet" (click)="removeRuleSet()"><i
                            class="fa fa-times"></i></button> -->
                </ng-container>

                <!-- <ng-container *queryArrowIcon>
                    <i class="fa fa-chevron-right px-2"></i>
                </ng-container> -->

                <!-- <ng-container *queryRemoveButton="let rule; let removeRule=removeRule">
                    <button type="button" (click)="removeRule(rule)"><i class="fa fa-times"></i></button>
                </ng-container> -->

                <!-- <ng-container *querySwitchGroup="let ruleset; let onChange=onChange">
                    <div *ngIf="ruleset">
                        <label>
                            <input type="radio" name="condition" [value]="'and'" [(ngModel)]="ruleset.condition"
                                (change)="onChange($event)" /> All
                        </label>
                        <label>
                            <input type="radio" name="condition" [value]="'or'" [(ngModel)]="ruleset.condition"
                                (change)="onChange($event)" /> Any
                        </label>
                    </div>
                </ng-container> -->
                <ng-container *querySwitchGroup="let ruleset; let onChange=onChange">
                    <div class="radio-icon-container">
                        <mat-radio-group *ngIf="ruleset" [(ngModel)]="ruleset.condition"
                            (ngModelChange)="onChange($event)">
                            <mat-radio-button [style.padding.px]="10" value="and">All</mat-radio-button>
                            <mat-radio-button [style.padding.px]="10" value="or">Any</mat-radio-button>
                        </mat-radio-group>
                        <i class="fa fa-info-circle fa-lg" aria-hidden="true" id="iButton"
                            title={{infoPopoverText}}></i>
                    </div>
                </ng-container>


                <!-- <ng-container *queryEntity="let rule; let entities=entities; let onChange=onChange">
                    <select [(ngModel)]="rule.entity" (change)="onChange($event, rule)">
                        <option *ngFor="let entity of entities" [value]="entity.value">{{entity.name}}</option>
                    </select>
                </ng-container> -->

                <!-- <ng-container *queryField="let rule; let fields=fields; let onChange=onChange; let getFields=getFields">
                    <select [(ngModel)]="rule.field" (change)="onChange($event, rule)">
                        <option *ngFor="let field of fields" [value]="field.value">{{field.name}}
                        </option>
                    </select>
                </ng-container> -->

                <!-- <ng-container *queryOperator="let rule; let operators=operators; let onChange=onChange">
                    <select [(ngModel)]="rule.operator" (change)="onChange(rule)">
                        <option *ngFor="let value of operators" [value]="value">{{value}}</option>
                    </select>
                </ng-container> -->

                <!-- <ng-container *queryInput="let rule; type: 'boolean'; let onChange=onChange">
                    <label>
                        <input type="checkbox" [(ngModel)]="rule.value" (change)="onChange()" /> Boolean
                    </label>
                </ng-container> -->

                <!-- <ng-container
                    *queryInput="let rule; let field=field; let options=options; type: 'category'; let onChange=onChange">
                    <select [(ngModel)]="rule.value" (change)="onChange()">
                        <option *ngFor="let opt of options" [value]="opt.value">{{opt.name}}</option>
                    </select>
                </ng-container> -->

                <!-- <ng-container *queryInput="let rule; type: 'date'; let onChange=onChange">
                    <input type="date" [(ngModel)]="rule.value" (change)="onChange()" />
                </ng-container> -->

                <ng-container *queryInput="let rule; let options=options; type: 'multiselect'; let onChange=onChange">
                    <select [(ngModel)]="rule.value" multiple (change)="onChange()" class="multiselect-input" size="4">
                        <option *ngFor="let opt of options" [value]="opt.value" [title]="opt.name">{{opt.name}}</option>
                    </select>
                </ng-container>

                <!-- <ng-container *queryInput="let rule; let field=field; type: 'number'; let onChange=onChange">
                    <input type="number" [(ngModel)]="rule.value" (change)="onChange()" />
                </ng-container> -->

                <!-- <ng-container *queryInput="let rule; let field=field; type: 'string'; let onChange=onChange">
                    <input type="text" [(ngModel)]="rule.value" (change)="onChange()" />
                </ng-container> -->

                <!-- <ng-container *queryInput="let rule; let field=field; type: 'textarea'; let onChange=onChange">
                    <textarea [(ngModel)]="rule.value" (change)="onChange()"></textarea>
                </ng-container> -->

                <div *ngFor="let rule of query.rules">
                    <pre>{{rule|json}}</pre>
                </div>
            </query-builder>

            <span *ngIf="enableDynamicQueryFilterSave && this.filterCriteria.id != -1">Name your Saved Filter: <input
                    type="text" [(ngModel)]="dynamicQueryFilterName"
                    (input)="onDynamicQueryFilterNameChange($event.target)"></span>
        </div>

        <button type="button" class="btn btn-primary btn" id="confirmationButton" (click)="onNo()">
            {{noText}}
        </button>
        <button type="button" class="btn btn-primary btn" id="confirmationButton" [disabled]="disableYesButton"
            (click)="onYes()">
            {{yesText}}
        </button>
    </div>
</div>

<!-- <textarea style="width: 100%; height: 300px;" readonly>
        {{query|json}}
</textarea> -->