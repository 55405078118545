import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'rc-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'ResiChain';
  constructor(
    private translate: TranslateService,
    private modalConfig: NgbModalConfig
  ) {
    modalConfig.backdrop = 'static';
  }
}
