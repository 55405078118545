import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../service/auth.service';

@Component({
  selector: 'rc-change-password-modal',
  templateUrl: './change-password-modal.component.html',
  styleUrls: ['./change-password-modal.component.css']
})
export class ChangePasswordModalComponent {
  @Input()
  userEmail: string = '';

  showOldPassword: boolean = false;
  showConfirmPassword: boolean = false;

  changePasswordForm: FormGroup;
  loading = false;
  submitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    public activeModal: NgbActiveModal,
    private authService: AuthService
  ) {
    this.changePasswordForm = this.formBuilder.group({
      oldPassword: ['', [Validators.required]],
      newPassword: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]],
    })
  }
  
  get f() { return this.changePasswordForm.controls; }

  onSubmit() {
    this.submitted = true;

    if (this.changePasswordForm.invalid) return;

    this.spinner.show();

    this.authService.changePassword(this.changePasswordForm.value).subscribe({
      next: (res: any) => {
        this.toastr.success(res.body.message);
        this.spinner.hide();
        this.activeModal.close(res.body);
      }, error: (err) => {
        console.log('Error', err)
        this.toastr.error(err.error.message);
        this.spinner.hide();
      }
    })
  }

}
