import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../service/admin.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from '../../service/auth.service';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AccountService } from '../../service/account.service';

@Component({
  selector: 'rc-select-entity-modal',
  templateUrl: './select-entity-modal.component.html',
  styleUrls: ['./select-entity-modal.component.css']
})
export class SelectEntityModalComponent implements OnInit {

  subscribers: Array<any> = [];
  selectedSubscriberId: number = -1;

  constructor(
    private adminService: AdminService,
    private spinner: NgxSpinnerService,
    private authService: AuthService,
    private toastrService: ToastrService,
    public activeModal: NgbActiveModal,
    private accountService: AccountService
  ) { }

  ngOnInit(): void {
    this.getSubscribersList();
  }

  getSubscribersList() {
    this.spinner.show();

    this.adminService.getSubscribersList().subscribe({
      next: (res: any) => {
        this.subscribers = res.subscribers;
        this.spinner.hide();
      }, error: (err) => {
        console.log("Error", err.error.message);
        this.spinner.hide();
      }, complete: () => { }
    })
  }

  onConfirm() {
    if (this.selectedSubscriberId != -1) {
      this.authService.setCookies('RC-X-selected-entity-id', this.selectedSubscriberId.toString());
      this.accountService.subscriberChangeEmitter.emit();
      this.activeModal.close('Y')
    } else {
      this.toastrService.error('Invalid entity.')
    }
  }
}
