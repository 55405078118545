import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'decimalFormat'
})
export class DecimalFormatPipe implements PipeTransform {

  transform(value: any): any {
    // Check if the value is a number
    if (typeof value === 'number') {
      // Format the number with commas and round to 2 decimal places
      return new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 0,  // Show no decimals if not needed
        maximumFractionDigits: 2   // Show up to 2 decimal places
      }).format(value);
    } else {
      // Return value as is if it's not a number or doesn't have decimals
      return value;
    }
  }

}
