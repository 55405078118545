import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.css']
})
export class ConfirmationModalComponent {
  @Input()
  text: string = '';

  @Input()
  yesText: string = '';
  
  @Input()
  noText: string = '';

  constructor(
    public activeModal: NgbActiveModal
  ) { }

  onNo() {
    this.activeModal.dismiss('X')
  }

  onYes() {
    this.activeModal.close('Y')
  }

}
