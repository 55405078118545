<ngx-spinner bdColor="rgba(51,51,51, 0.8)" color="#fff" [fullScreen]="true" size="medium">
</ngx-spinner>

<!-- <div id="myModal" style="display: block;">
    <div class="modal-dialog">
        <div class="modal-content"> -->
<div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">
        Change Password
    </h5>
    <button type="button" class="btn-close" (click)="activeModal.dismiss('X')"></button>
</div>
<div class="modal-body">

    <form id="changePasswordForm" [formGroup]="changePasswordForm" (ngSubmit)="onSubmit()" autocomplete="off">

        <div class="position-relative mb-3">
            <input [type]="showOldPassword ? 'text' : 'password'" id="oldPassword" name="oldPassword"
                class="form-control form-control-lg" placeholder="Your old password *" formControlName="oldPassword"
                [ngClass]="{ 'is-invalid': submitted && f['oldPassword'].errors }">
            <i class="fa fa-eye-slash _pswd position-absolute end-icon" (click)="showOldPassword=true" *ngIf="!showOldPassword"></i>
            <i class="fa fa-eye _pswd position-absolute end-icon" (click)="showOldPassword=false" *ngIf="showOldPassword"></i>

            <div *ngIf="submitted && f['oldPassword'].errors" class="invalid-feedback">
                <div *ngIf="f['oldPassword'].errors['required']">Old Password is required</div>
            </div>
        </div>

        <div class="mb-3">
            <input type="password" id="newPassword" name="newPassword" class="form-control form-control-lg"
                placeholder="Your new password *" formControlName="newPassword"
                [ngClass]="{ 'is-invalid': submitted && f['newPassword'].errors }">

            <div *ngIf="submitted && f['newPassword'].errors" class="invalid-feedback">
                <div *ngIf="f['newPassword'].errors['required']">New Password is required</div>
            </div>
        </div>

        <div class="position-relative mb-3">
            <input [type]="showConfirmPassword ? 'text' : 'password'" id="confirmPassword" name="confirmPassword"
                class="form-control form-control-lg" placeholder="Confirm new password *"
                formControlName="confirmPassword"
                [ngClass]="{ 'is-invalid': submitted && f['confirmPassword'].errors }">
            <i class="fa fa-eye-slash _pswd position-absolute end-icon" (click)="showConfirmPassword=true" *ngIf="!showConfirmPassword"></i>
            <i class="fa fa-eye _pswd position-absolute end-icon" (click)="showConfirmPassword=false" *ngIf="showConfirmPassword"></i>

            <div *ngIf="submitted && f['confirmPassword'].errors" class="invalid-feedback">
                <div *ngIf="f['confirmPassword'].errors['required']">Confirm new password is required</div>
            </div>
        </div>

        <button type="submit" class="btn btn-primary btn-lg" id="modal-button">
            Change Password
        </button>
    </form>
</div>
<!-- </div>
    </div>
</div> -->