import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Login } from '../model/account.model';
import { AUTH_SERVICE_API_URL } from '../../app.constant';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

type LoginResponse = {
  token: string;
  role: string;
  email: string;
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public logoutEmitter = new EventEmitter();

  constructor(
    private http: HttpClient,
    private router: Router,
    private cookieService: CookieService
  ) { }

  checkToken() {
    return this.http.get<any>(AUTH_SERVICE_API_URL + '/check_token', { observe: 'response' });
  }

  getToken(): string {
    const tokenInCookie: string | null = this.getCookies('RC-X-token');
    return tokenInCookie ?? '';
  }

  getRole(): string {
    const roleInCookie: string | null = this.getCookies('RC-X-role');
    return roleInCookie ?? '';
  }

  login(credentials: Login): Observable<void> {
    return this.http
      .post<LoginResponse>(AUTH_SERVICE_API_URL + '/authenticate', credentials)
      .pipe(map(response => this.authenticateSuccess(response)));
  }

  logout(): Observable<void> {
    return this.http.get(AUTH_SERVICE_API_URL + '/logout')
      .pipe(map(response => {
        this.logoutEmitter.emit(null);
        location.href = location.origin;
      }))
  }

  private authenticateSuccess(response: LoginResponse): void {
    this.setCookies('RC-X-token', response.token)
    this.setCookies('RC-X-role', response.role)
    this.router.navigateByUrl('/dashboard');
  }

  setCookies(cookieName: string, cookieValue: string) {
    this.cookieService.set(cookieName, cookieValue, {path:'/'});
  }

  getCookies(cookieName: string) {
    return this.cookieService.get(cookieName);
  }

  changePassword(postData: any) {
    return this.http.post<any>(AUTH_SERVICE_API_URL + '/change_password', postData, { observe: 'response' });
  }

  sendPasswordResetLink(postData: any) {
    return this.http.post<any>(AUTH_SERVICE_API_URL + '/reset_password_link', postData, { observe: 'response' });
  }

  resetPassword(postData: any) {
    return this.http.post<any>(AUTH_SERVICE_API_URL + '/reset_password', postData, { observe: 'response' });
  }
}
