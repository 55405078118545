import { Component, Input, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { UserService } from '../../service/user.service';
import { GlobalService } from '../../service/global.service';
import { Router } from '@angular/router';

@Component({
  selector: 'rc-details-modal',
  templateUrl: './details-modal.component.html',
  styleUrls: ['./details-modal.component.css']
})
export class DetailsModalComponent implements OnInit {

  @Input()
  locationData: any;

  @Input()
  dynamicQueryFilters: any = {}

  supplyChainDetails: any | null;

  constructor(
    private spinner: NgxSpinnerService,
    public activeModal: NgbActiveModal,
    private userService: UserService,
    private globalService: GlobalService,
    private router: Router,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    // console.log('locationData', this.locationData);
    this.getSupplyChainDetails();
  }

  getSupplyChainDetails() {
    const postData = {
      locationData: this.locationData,
      dynamicQueryFilters: this.dynamicQueryFilters
    }

    this.spinner.show();

    this.userService.getDashboardMapLocationSupplyChainDetails(postData).subscribe({
      next: (res: any) => {
        // console.log("getDashboardMapLocationSupplyChainDetails", res);
        this.supplyChainDetails = res.body;
        this.spinner.hide();
      },
      error: (err) => {
        console.log("Error", err);
        this.spinner.hide();
        this.toastr.error(err.error.message)
      }
    });
  }

  goToOrderPage(stageStatus: string, location_details: any = null) {
    if (location_details.location_type == 'HQ') {
      return; // Don't allow the click-through if location type is HQ.
    }
    
    const computedDynamicQueryFilter = this.globalService.computeDashboardDynamicQueryFilters(stageStatus, this.dynamicQueryFilters, location_details);
    // console.log("computedDynamicQueryFilter", computedDynamicQueryFilter);
    if (this.dynamicQueryFilters.rules && this.dynamicQueryFilters.rules.length > 0 && this.dynamicQueryFilters.condition == 'or') {
      this.toastr.info('This combination of filters (using both ‘All’ and ‘Any’ options) is not currently supported. You may manually proceed to the Orders page and add your necessary filters there.')
      return;
    }

    this.activeModal.close('X');

    this.globalService.setDynamicFilterData(computedDynamicQueryFilter);
    this.router.navigateByUrl('/pages/orders?redirected-from=alerts&primary-filter=1');
  }
  
}
