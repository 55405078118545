<div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">
        Details
    </h5>
    <button type="button" class="btn-close-custom" (click)="onNo()">
        <img src="assets/images/cross_white.png" alt="Close">
    </button>
</div>
<div class="modal-body">
    <div class="card-body">
        <div class="mb-3">
            <ng-container *ngFor="let key of objectKeys(popupData)">
                <li>
                    {{ titleStringMap[key] }} :
                    <ng-container *ngIf="popupData[key] !== null; else dash">
                        {{ popupData[key] | decimalFormat }}
                    </ng-container>
                    <ng-template #dash>&nbsp;--</ng-template>
                </li>
            </ng-container>
        </div>
        <button type="button" class="btn btn-primary btn" id="confirmationButton" (click)="onNo()">
            Close
        </button>
    </div>
</div>