import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { AdminService } from '../../service/admin.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { GlobalService } from '../../service/global.service';

@Component({
  selector: 'rc-add-user-modal',
  templateUrl: './add-user-modal.component.html',
  styleUrls: ['./add-user-modal.component.css']
})
export class AddUserModalComponent {

  @Input()
  roles: Array<any> = [];

  user: any;
  addUserForm: FormGroup;
  loading = false;
  submitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private adminService: AdminService,
    private toastr: ToastrService,
    public activeModal: NgbActiveModal,
    private globalService: GlobalService,
  ) {
    this.user = this.globalService.user;
    this.addUserForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      role: ['-1', [Validators.required]]
    })
  }
  
  get f() { return this.addUserForm.controls; }

  onSubmit() {
    this.submitted = true;

    if (this.addUserForm.invalid) return;

    this.spinner.show();

    this.adminService.addUser(this.addUserForm.value).subscribe({
      next: (res: any) => {
        this.toastr.success(res.body.message);
        this.spinner.hide();
        this.activeModal.close(res.body);
      }, error: (err) => {
        console.log('Error', err)
        this.toastr.error(err.error.message);
        this.spinner.hide();
      }, complete: () => { }
    })
  }


}
